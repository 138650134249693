import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, useFormikContext, withFormik } from 'formik';
import { BsArrowReturnRight } from 'react-icons/bs';
import * as Yup from 'yup';

import { colors, keyFilterConsultaRsql, mensagensDeValidacao, services } from 'Common';
import {
	AutoProgressBar,
	ButtonCancelar,
	ButtonExcluir,
	ButtonNovo,
	ButtonSalvar,
	Checkbox,
	Col,
	ColorPicker,
	Form,
	FormActions,
	FormContent,
	Grid,
	If,
	InputField,
	Modal,
	SingleSelectCategoria,
	confirm,
	estadosBotaoCancelar,
	estadosBotaoNovo,
	estadosBotaoSalvar,
} from 'components';

import { validarFormulario } from '../../../../../Util';
import { helpMessage } from './Help';
import {
	asyncCreateRegistro,
	asyncDeleteRegistro,
	asyncGetCategoriaMesmoNome,
	asyncGetRegistro,
	asyncUpdateRegistro,
} from './Requests';
import { converterCategoriaParaApi, converterCategoriaParaFormulario } from './Util/categoriaConverter';
import { INITIAL_VALUES } from './Util/constantes';

const errorStyle = {
	fontSize: '12px',
	color: colors.vermelho,
	margin: '0px 8px 20px',
	height: '0px',
};

function ModalCategoriaView({
	visible,
	onHide,
	dirty,
	informacoesPermissoes,
	isMobile,
	insertJustOne,
	categoriaSelecionada,
}) {
	const { values, errors, resetForm, setFieldValue, setFieldError, initialValues, handleSubmit, validateForm } =
		useFormikContext();

	const prefixoHeader = values.id ? 'Editar categoria' : 'Cadastrar nova categoria';
	const isEdicao = !!values.id;
	const valueDespesa = values.categoriaSuperior ? values.categoriaSuperior.registro.despesa : values.despesa;
	const valueReceita = values.categoriaSuperior ? values.categoriaSuperior.registro.receita : values.receita;
	const podeEditarTipoCategoria = (isEdicao && !informacoesPermissoes.podeEditar) || Boolean(values.categoriaSuperior);
	const estadoBotaoNovo = dirty ? estadosBotaoNovo.SALVAR_E_NOVO : estadosBotaoNovo.NOVO;
	function onClickNovo() {
		dirty ? (e) => salvar(e, novo()) : novo();
	}

	useEffect(() => {
		if (values.id) {
			asyncGetRegistro(values.id, ({ data }) => {
				resetForm({ values: converterCategoriaParaFormulario(data) });
			});
		} else {
			setTimeout(() => {
				novo();
			}, 1);
		}

		setTimeout(() => {
			document.getElementById('CategoriasInputFieldNome')?.focus();
		}, 500);
	}, []);

	function novo() {
		resetForm({
			values: { ...initialValues },
		});
	}

	function excluir() {
		confirm('Confirmação', `Tem certeza que deseja excluir a categoria ${values.nome}?`, () => {
			asyncDeleteRegistro(values.id, () => {
				onHide(values);
			});
		});
	}

	async function salvar(e, novoOnSuccess) {
		handleSubmit();

		if (await validarFormulario({ validateForm, values })) {
			if (await validarNome()) {
				if (!validaSubCategoria(values.categoriaSuperior?.value, categoriaSelecionada)) {
					if (values.id) {
						asyncUpdateRegistroInterno(converterCategoriaParaApi(values), novoOnSuccess);
					} else {
						asyncCreateRegistroInterno(converterCategoriaParaApi(values), novoOnSuccess);
					}
				} else {
					setFieldError('categoriaSuperior', 'A categoria superior não pode ser uma categoria filha dela mesma');
				}
			} else {
				setFieldError('nome', 'O nome informado já existe');
			}
		}
	}

	function validaSubCategoria(idCategoriaSuperior, categoriaSelecionada) {
		let subCategorias = [];

		if (categoriaSelecionada?.subCategorias?.length) {
			categoriaSelecionada.subCategorias.forEach((categoria) => {
				subCategorias = percoreSubCategorias(categoria, subCategorias);
			});
		}

		return !!subCategorias.filter((categoria) => categoria.id === idCategoriaSuperior).length;
	}

	function percoreSubCategorias(categorias, subCategorias) {
		subCategorias.push({ id: categorias.id });

		if (categorias?.subCategorias?.length) {
			categorias.subCategorias.forEach((categoria) => {
				percoreSubCategorias(categoria, subCategorias);
			});
		}

		return subCategorias;
	}

	async function validarNome() {
		let nomeJaExiste = false;
		await asyncGetCategoriaMesmoNome(values.nome, (e) => {
			if (e.data.content.length > 0 && e.data.content[0].id !== values.id) {
				nomeJaExiste = true;
			}
		});
		return !nomeJaExiste;
	}

	function asyncUpdateRegistroInterno(values, novoOnSuccess) {
		asyncUpdateRegistro(values, () => {
			if (novoOnSuccess) {
				novoOnSuccess();
			} else {
				onHide(values);
			}
		});
	}

	function asyncCreateRegistroInterno(values, novoOnSuccess) {
		if (values) {
			asyncCreateRegistro(values, ({ data: categoria }) => {
				if (novoOnSuccess) {
					novoOnSuccess();
				} else {
					onHide({ ...values, id: categoria.id });
				}
			});
		}
	}

	function cancelar() {
		if (dirty) {
			resetForm({ values: initialValues });
		} else {
			onHide();
		}
	}

	function handleChangeColor(color) {
		if (!dirty && !values.aparencia) {
			resetForm({ values: { ...values, aparencia: color } });
		} else {
			setFieldValue('aparencia', color);
		}
	}

	function onChangeTipoReceita() {
		if (values.receita) {
			setFieldValue('favoritaReceita', false);
		}

		setFieldValue('receita', !values.receita);
	}

	function onChangeTipoDespesa() {
		if (values.despesa) {
			setFieldValue('favoritaDespesa', false);
		}

		setFieldValue('despesa', !values.despesa);
	}

	function onChangeCategoriaSuperior(categoriaSuperior) {
		setFieldValue('categoriaSuperior', categoriaSuperior);
		if (categoriaSuperior) {
			setFieldValue('receita', categoriaSuperior?.registro?.receita);
			setFieldValue('despesa', categoriaSuperior?.registro?.despesa);
			setFieldValue('aparencia', categoriaSuperior?.registro?.aparencia);
		}
	}

	return (
		<Modal header={prefixoHeader} visible={visible} onHide={onHide}>
			<AutoProgressBar />
			<Form>
				<FormActions>
					<ButtonCancelar
						estadoBotao={dirty ? estadosBotaoCancelar.CANCELAR : estadosBotaoCancelar.VOLTAR}
						onClick={() => cancelar()}
						{...informacoesPermissoes}
					/>
					<ButtonSalvar
						estadoBotao={estadosBotaoSalvar.SALVAR}
						disabled={!dirty}
						onClick={() => salvar()}
						{...informacoesPermissoes}
					/>
					<ButtonNovo
						onClick={() => onClickNovo()}
						hidden={(!dirty && !values.id) || insertJustOne}
						estadoBotao={estadoBotaoNovo}
						{...informacoesPermissoes}
					/>
					<ButtonExcluir hidden={!values.id} onClick={() => excluir()} {...informacoesPermissoes} />
				</FormActions>
				<FormContent>
					<Grid>
						<Field
							sm="6"
							md="6"
							lg="8"
							xl="8"
							component={InputField}
							label="Nome da categoria"
							autoFocus
							obrigatorio
							disabled={isEdicao && !informacoesPermissoes.podeEditar}
							keyfilter={keyFilterConsultaRsql}
							name="nome"
							size={255}
							helpMessage={helpMessage.nome}
							id="CategoriasInputFieldNome"
							{...informacoesPermissoes}
						/>
						<Field
							sm="6"
							md="6"
							lg="4"
							xl="4"
							name="categoriaSuperior"
							label="Mostrar esta categoria dentro de"
							helpMessage={`Caso esta opção estiver preenchida, a categoria ficará disponível como subcategoria ${
								values.categoriaSuperior ? `de ${values.categoriaSuperior.label}` : 'do valor deste campo'
							}`}
							component={SingleSelectCategoria}
							esconderBotao
							value={values.categoriaSuperior}
							onChange={(e) => onChangeCategoriaSuperior(e)}
							url={`${services.GESTOR}/v1/categorias/relacoes/categorias_superiores`}
							disabled={isEdicao && !informacoesPermissoes.podeEditar}
						/>
						<Grid style={{ width: '100%' }}>
							<Col sm="12" md="6" lg="6" xl="6">
								<Field
									component={Checkbox}
									label="Disponível para receitas"
									name="receita"
									disabled={podeEditarTipoCategoria}
									helpMessage={helpMessage.disponivelReceita}
									checked={valueReceita}
									onChange={() => onChangeTipoReceita()}
								/>
								<Col style={{ display: 'inline-flex', padding: '0px 0.5em' }}>
									<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
										<BsArrowReturnRight size="22" />
									</div>
									<Field
										component={Checkbox}
										label="Categoria favorita para receitas"
										name="categoriaFavoritaParaReceita"
										helpText="Marque para usar como favorita"
										disabled={!valueReceita || (isEdicao && !informacoesPermissoes.podeEditar)}
										onChange={() => setFieldValue('favoritaReceita', !values.favoritaReceita)}
										helpMessage={!isMobile ? helpMessage.favoritaReceita : null}
										checked={values.favoritaReceita}
									/>
								</Col>
							</Col>
							<Col sm="12" md="6" lg="6" xl="6">
								<Field
									component={Checkbox}
									label="Disponível para despesas"
									name="despesa"
									disabled={podeEditarTipoCategoria}
									onChange={() => onChangeTipoDespesa()}
									helpMessage={!isMobile ? helpMessage.disponivelDespesa : null}
									checked={valueDespesa}
								/>
								<Col style={{ display: 'inline-flex', padding: '0px 0.5em' }}>
									<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
										<BsArrowReturnRight size="22" />
									</div>
									<Field
										component={Checkbox}
										label="Categoria favorita para despesas"
										name="categoriaFavoritaParaDespesas"
										helpText="Marque para usar como favorita"
										disabled={!valueDespesa || (isEdicao && !informacoesPermissoes.podeEditar)}
										onChange={() => setFieldValue('favoritaDespesa', !values.favoritaDespesa)}
										helpMessage={!isMobile ? helpMessage.favoritaDespesa : null}
										checked={values.favoritaDespesa}
									/>
								</Col>
							</Col>
						</Grid>
						<If test={errors && errors.receitaDespesa}>
							<span style={{ ...errorStyle }}>A categoria deve pertencer a um tipo</span>
						</If>

						<Col sm="12" md="12" lg="12" xl="12">
							<ColorPicker
								disabled={podeEditarTipoCategoria}
								value={values.categoriaSuperior ? values.categoriaSuperior.registro.aparencia : values.aparencia}
								onBlur={(e) => handleChangeColor(e.target.value)}
							/>
						</Col>
					</Grid>
				</FormContent>
			</Form>
		</Modal>
	);
}

const ModalCategoriaForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues(props) {
		if (props.categoriaSelecionada) {
			return props.categoriaSelecionada;
		}

		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};

		if (!values.receita && !values.despesa) {
			errors.receitaDespesa = 'A categoria deve pertencer ao menos um tipo';
		}

		if (values.categoriaSuperior && values.categoriaSuperior?.value === values.id) {
			errors.categoriaSuperior = 'A categoria superior não pode ser a mesma categoria';
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		nome: Yup.string()
			.max(255, 'O nome da categoria não pode ultrapassar 255 caracteres')
			.nullable()
			.required(mensagensDeValidacao.OBRIGATORIO),
	}),

	handleSubmit: () => {},
})(ModalCategoriaView);

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default withRouter(connect(mapStateToProps)(ModalCategoriaForm));
