import { useState } from 'react';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import ModalAssinatura from '../../../views/documentos/AssinaturaEletronica/Modal';
import { StatusDocumentoParaSelect } from './Util/constantes';
import { statusDocumento } from 'views/documentos/AssinaturaEletronica/Util/constantes';

export default function SingleSelectAssinatura(props) {
	const { url, service } = props;

	const [visible, setVisible] = useState(false);
	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.INSERIR)
	);
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.DOCUMENTOS_ASSINATURA, permissoes.VISUALIZAR)
	);
	const [resetPesquisa, setResetPesquisa] = useState(false);

	function buscarUrlPesquisa(pesquisa, page) {
		return `${url}?query=identificacao=contains="*${pesquisa}*";arquivado=="false"&page=${page}&size=50&sort=identificacao`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro?.id) {
			props.onChange({
				label: montarLabel({
					identificacao: novoRegistro.identificacao,
					status: statusDocumento.AGUARDANDO_ASSINATURAS,
				}),
				value: novoRegistro.id,
				registro: {
					id: novoRegistro.id,
					identificacao: novoRegistro.identificacao,
					status: statusDocumento.AGUARDANDO_ASSINATURAS,
					dataCriacao: novoRegistro.dataCriacao,
				},
			});
			setResetPesquisa(true);
		}
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		let documento = `${row.identificacao} - ${StatusDocumentoParaSelect[row.status]}`;
		return documento;
	}

	function buscarTitleBotaoNovo() {
		if (podeVisualizar && podeInserir) {
			return 'Adicionar novo documento para assinatura';
		}
		return 'Você não possui permissão para executar essa ação';
	}

	return (
		<>
			<ModalAssinatura service={service} visible={visible} onHide={onHide} />
			<SingleSelect
				{...props}
				titleBotaoNovo={buscarTitleBotaoNovo()}
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				disabledButton={!podeVisualizar || !podeInserir}
				onChange={props.onChange}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

SingleSelectAssinatura.defaultProps = {
	label: 'Documento',
};

SingleSelectAssinatura.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
};
