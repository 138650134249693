import { useRef, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { buscarDadosLoginLocalStorage, copiarObjeto, formatarMonetarioDecimais } from 'Common';
import {
	Button,
	Col,
	Grid,
	InputMoney,
	Modal,
	MultiSelectFiliais,
	NenhumRegistroEncontrado,
	Form,
	FormActions,
	FormContent,
	ButtonCancelar,
	ButtonSalvar,
	estadosBotaoSalvar,
	estadosBotaoCancelar,
	SingleSelectFilial,
	If,
	InputDouble,
} from 'components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Field } from 'formik';

export default function FieldsCustos({ values, setFieldValue, informacoesPermissoes }) {
	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(1);
	const [visibleModalReplicacao, setVisibleModalReplicacao] = useState(false);
	const [filialOrigem, setFilialOrigem] = useState(null);
	const [filiaisDestino, setFiliaisDestino] = useState([]);
	const [resetPesquisaFiliaisDestino, setResetPesquisaFiliaisDestino] = useState(false);

	const menuAlterarEmLote = useRef(null);
	const itensMenu = buscaritensMenu();

	const decimaisPreco = buscarDadosLoginLocalStorage()?.filialConectada?.parametrosCadastros?.decimaisPreco ?? 2;

	const indexFilialConectada =
		values.filiais.map((e) => e.filialConectada).indexOf(true) > -1
			? values.filiais.map((e) => e.filialConectada).indexOf(true)
			: 0;

	function buscaritensMenu() {
		let res = [];
		const replicar = {
			label: 'Replicar preço custo para filiais',
			command: handleExibirModalReplicacao,
		};
		res.push(replicar);
		return res;
	}

	function onSort(element) {
		setSortField(element.sortField);
		setSortOrder(element.sortOrder);
	}

	function renderPrecoCusto(row, index) {
		function changePrecoCusto(event) {
			const filial = copiarObjeto(row);
			filial.precoCusto = event.target.value ?? 0;
			setFieldValue(`filiais[${index}]`, filial);
		}

		return (
			<span>
				<InputMoney
					name="precoCusto"
					value={row.precoCusto}
					onChange={changePrecoCusto}
					allowNegative={false}
					decimalScale={decimaisPreco}
					{...informacoesPermissoes}
				/>
			</span>
		);
	}

	function renderNomeFilial(row) {
		return `${row.filialCodigo} - ${row.filialNomeApresentacao}`;
	}

	function handleChangeFilialOrigem(event) {
		setFilialOrigem(event);
		setFiliaisDestino(filiaisDestino.filter((item) => item.value !== event?.value));
		setResetPesquisaFiliaisDestino(true);
	}

	function handleConfirmar() {
		let valorOrigem = values.filiais.filter((item) => item.filialId === filialOrigem.value)[0].precoCusto ?? 0;
		const filiais = copiarObjeto(values.filiais);
		filiais.forEach((filial) => {
			if (filiaisDestino.filter((item) => item.value === filial.filialId).length > 0) {
				filial.precoCusto = valorOrigem;
			}
		});
		setFieldValue(`filiais`, filiais);
		setVisibleModalReplicacao(false);
	}

	function handleExibirModalReplicacao() {
		setFilialOrigem(null);
		setFiliaisDestino([]);
		setVisibleModalReplicacao(true);
	}

	function filtrarOptionsFilialDestino(options) {
		return options.filter((item) => item.value !== filialOrigem?.value);
	}

	return (
		<>
			<Grid>
				<If test={values.filiais.length > 1}>
					<Col>
						<Fieldset className="fieldset">
							<Grid>
								<Col sm="8" md="9" lg="10" xl="10" />
								<Col sm="4" md="3" lg="2" xl="2">
									<Button
										className="p-button-primary step-tabela-preco-alterar-lote"
										label="Alterar em lote"
										icon="fa fa-wrench"
										style={{ width: '100%' }}
										aria-controls="popup_menu"
										aria-haspopup
										disabled={values.filiais.length === 1}
										onClick={(event) => {
											menuAlterarEmLote.current.toggle(event);
										}}
									/>
								</Col>
								<DataTable
									className="table"
									rowClassName="table-row"
									cellClassName="table-row-cell"
									responsive
									value={values.filiais}
									sortField={sortField}
									sortOrder={sortOrder}
									onSort={onSort}
									emptyMessage={<NenhumRegistroEncontrado />}
									style={{ width: '100%' }}
								>
									<Column
										field="filialCodigo"
										header="Filial"
										body={renderNomeFilial}
										style={{ width: '55%' }}
										sortable
									/>
									<Column
										field="ultimoCusto"
										header="Último custo"
										body={(row) => formatarMonetarioDecimais(row.ultimoCusto, decimaisPreco)}
										style={{ width: '15%' }}
										sortable
									/>
									<Column
										field="custoMedio"
										header="Custo médio"
										body={(row) => formatarMonetarioDecimais(row.custoMedio, decimaisPreco)}
										style={{ width: '15%' }}
										sortable
									/>
									<Column
										field="precoCusto"
										header="Preço custo"
										body={(row, index) => renderPrecoCusto(row, index.rowIndex)}
										style={{ width: '15%' }}
										sortable
									/>
								</DataTable>
							</Grid>
						</Fieldset>
					</Col>
				</If>
				<If test={values.filiais.length === 1}>
					<Field
						sm="12"
						md="4"
						lg="3"
						xl="3"
						component={InputDouble}
						label="Último custo"
						name="ultimoCusto"
						id="ultimoCusto"
						value={values.filiais[indexFilialConectada].ultimoCusto}
						decimalScale={decimaisPreco}
						disabled
						size={13}
						{...informacoesPermissoes}
					/>

					<Field
						sm="12"
						md="4"
						lg="3"
						xl="3"
						component={InputDouble}
						label="Custo médio"
						name="custoMedio"
						id="custoMedio"
						value={values.filiais[indexFilialConectada].custoMedio}
						decimalScale={decimaisPreco}
						disabled
						size={13}
						{...informacoesPermissoes}
					/>

					<Field
						sm="12"
						md="4"
						lg="3"
						xl="3"
						component={InputDouble}
						label="Preço custo"
						name="precoCusto"
						id="precoCusto"
						onChange={(e) => setFieldValue(`filiais.${indexFilialConectada}.precoCusto`, e.target.value ?? 0)}
						value={values.filiais[indexFilialConectada].precoCusto}
						decimalScale={decimaisPreco}
						size={13}
						{...informacoesPermissoes}
					/>
				</If>
			</Grid>
			<Menu model={itensMenu} style={{ minWidth: '255px' }} popup={true} ref={menuAlterarEmLote} />
			<Modal
				closeOnEsc={true}
				showCloseIcon={true}
				visible={visibleModalReplicacao}
				onHide={() => setVisibleModalReplicacao(false)}
				header="Replicar preço custo para filiais"
			>
				<Form>
					<FormActions>
						<ButtonCancelar
							estadoBotao={estadosBotaoCancelar.VOLTAR}
							onClick={() => setVisibleModalReplicacao(false)}
							{...informacoesPermissoes}
						/>
						<ButtonSalvar
							estadoBotao={estadosBotaoSalvar.CONFIRMAR}
							onClick={handleConfirmar}
							disabled={filiaisDestino.length === 0}
							{...informacoesPermissoes}
						/>
					</FormActions>
					<FormContent>
						<Grid>
							<Col>
								<SingleSelectFilial
									label="Filial de origem"
									value={filialOrigem}
									handleChangeFilial={handleChangeFilialOrigem}
									style={{ width: '100%', backgroundColor: 'fff' }}
								/>
							</Col>
							<Col>
								<MultiSelectFiliais
									label="Filial(is) de destino"
									style={{ width: '100%' }}
									value={filiaisDestino}
									disabled={!filialOrigem}
									onChange={setFiliaisDestino}
									onlyUserFiliais
									resetPesquisa={resetPesquisaFiliaisDestino}
									setResetPesquisa={setResetPesquisaFiliaisDestino}
									converterOptions={filtrarOptionsFilialDestino}
								/>
							</Col>
						</Grid>
					</FormContent>
				</Form>
			</Modal>
		</>
	);
}
