import { useEffect, useState } from 'react';

import './index.css';

function ColorPicker({ disabled, value, onChange, onBlur, style, ...props }) {
	const [cor, setCor] = useState(value || '#000000');

	useEffect(() => {
		setCor(value || '#000000');
	}, [value]);

	function internalOnChange(event) {
		setCor(event.target.value);
		if (typeof onChange === 'function') {
			onChange(event);
		}
	}

	function internalOnBlur(event) {
		if (typeof onBlur === 'function') {
			onBlur(event);
		}
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<span>Cor da categoria</span>
			<input
				id="inputColor"
				type="color"
				disabled={disabled}
				value={cor}
				onChange={internalOnChange}
				onBlur={internalOnBlur}
				{...props}
				style={{
					...style,
				}}
			/>
		</div>
	);
}

export default ColorPicker;
