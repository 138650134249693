const colors = {
	principal: '#36a3b2',
	principalDark: '#2D789E',
	principalHoverTransparente: '#36a3b226',
	principalHoverTable: '#e8f5f5',
	principalGrafite: '#2e2e2e',

	cinza: '#888888',
	cinzaDark: '#333333',
	cinzaText: '#495057',
	cinzaLight: '#F2F2F2',
	cinzaBackground: '#e7ecf0',

	vermelho: '#b71c1c',
	vermelhoHoverTransparente: '#b71c1c26',
	vermelhoLight: '#ffcdd2',
	vermelhoDark: '#73000c',

	amarelo: '#765100',
	amareloButton: '#d9a300',
	amareloLight: '#FFECB3',
	warning: '#ff9800',

	azul: '#01579B',
	azulLight: '#b3e5fc',
	azulHoverTransparente: '#00619570',

	verde: '#1b5e20',
	verdeButton: '#34a835',
	verdeDark: '#1D6D51',
	verdeLight: '#dcedc8',

	branco: '#fff',
	brancoTransparente: '#ffffff00',
};

export { colors };
