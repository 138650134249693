import { services } from 'Common';
import { SingleSelect } from 'components';

function SingleSelectFilial({ value, handleChangeFilial, showLabel, resetPesquisa, setResetPesquisa, ...props }) {
	function getUrlPesquisa(pesquisa, page) {
		return `${services.GESTOR}/v1/usuarios/filiais?filter=${pesquisa}&page=${page}&size=50`;
	}

	function createLabel(row) {
		return `${row.codigo} - ${row.nomeApresentacao ?? row.nome}`;
	}

	return (
		<SingleSelect
			{...props}
			buscarUrlPesquisa={getUrlPesquisa}
			montarLabel={createLabel}
			onChange={handleChangeFilial}
			isClearable={false}
			esconderBotao
			errors={props.errors}
			useFormErrors={false}
			useFormTouched={false}
			touched
			resetPesquisa={resetPesquisa}
			setResetPesquisa={setResetPesquisa}
			menuPortalTarget={document.body}
			placeholder="Digite para pesquisar"
			value={value}
		/>
	);
}

export { SingleSelectFilial };
