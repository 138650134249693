import { useState, useEffect, useRef } from 'react';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';
import { buscarDadosLoginLocalStorage, usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import ModalPessoas from '../../../views/cadastros/Pessoas/Modal';
import { tabsCadastro } from '../../../views/cadastros/Pessoas/Util/constantes';
import usePrevious from '../../../Common/Hooks/usePrevious';

export default function SingleSelectPessoaEmail(props) {
	const { url, idPessoa, afterNewEmail } = props;

	const [visible, setVisible] = useState(false);
	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR));
	const [podeVisualizar, setPodeVisualizar] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.VISUALIZAR));
	const [resetarPesquisa, setResetarPesquisa] = useState(false);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.resetarPesquisa !== props?.resetarPesquisa) {
				setResetarPesquisa(!resetarPesquisa);
			}
		} else afterFirstRenderRef = true;
	}, []);

	function buscarUrlPesquisa(pesquisa, page) {
		return `${url}?query=email=contains="*${pesquisa}*"&page=${page}&size=50&sort=email`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function onHide() {
		esconderModal();
		setResetarPesquisa(!resetarPesquisa);
		if (afterNewEmail) {
			afterNewEmail();
		}
	}

	function montarLabel(row) {
		return row.email;
	}

	return (
		<>
			<ModalPessoas onHide={onHide} visible={visible} idPessoa={idPessoa} tabSelecionada={tabsCadastro.EMAILS} />
			<SingleSelect
				{...props}
				buscarUrlPesquisa={buscarUrlPesquisa}
				titleBotaoNovo="Adicionar novo e-mail"
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				disabledButton={!podeInserirEVisualizar}
				onChange={props.onChange}
				resetarPesquisa={resetarPesquisa}
			/>
		</>
	);
}

SingleSelectPessoaEmail.defaultProps = {
	label: 'Email',
};

SingleSelectPessoaEmail.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
	idPessoa: propTypes.string.isRequired,
};
