import { useEffect } from 'react';
import propTypes from 'prop-types';

import MultipleSelect from '../MultipleSelect';
import { buscarDadosLoginLocalStorage, services } from 'Common';

export default function MultiSelectFiliais(props) {
	const { startConnectedFilial = false, onlyUserFiliais = false } = props;
	const dadosLogin = buscarDadosLoginLocalStorage();

	useEffect(async () => {
		if (startConnectedFilial) {
			let filialConectada = {
				label: `${dadosLogin.filialConectada?.codigo} - ${dadosLogin.filialConectada?.nomeApresentacao}`,
				registro: {
					cnpj: dadosLogin.filialConectada?.cnpj ?? null,
					cpf: dadosLogin.filialConectada?.cpf ?? null,
					codigo: dadosLogin.filialConectada?.codigo,
					id: dadosLogin.filialConectada?.id,
					nomeApresentacao: dadosLogin.filialConectada?.nomeApresentacao,
					situacao: dadosLogin.filialConectada?.situacao,
				},
				value: dadosLogin.filialConectada?.id,
			};
			await props.onChange([filialConectada]);
		}
	}, []);

	function buscarUrlPesquisa() {
		if (onlyUserFiliais) {
			return `${services.GESTOR}/v1/usuarios/filiais`;
		}

		return `${services.GESTOR}/v1/organizacoes/filiais`;
	}

	function montarLabel(row) {
		if (row) {
			return `${row.codigo} - ${row.nomeApresentacao ?? row.nome}`;
		}
	}

	return (
		<>
			<MultipleSelect
				{...props}
				montarLabel={montarLabel}
				buscarUrlPesquisa={buscarUrlPesquisa}
				onChange={props.onChange}
			/>
		</>
	);
}

MultiSelectFiliais.propTypes = {
	// Define se o campo é obrigatório no formulário
	obrigatorio: propTypes.bool,
};

MultiSelectFiliais.defaultProps = {
	label: 'Filiais de acesso',
	obrigatorio: true,
};
