import { useState, useEffect, useRef } from 'react';
import usePrevious from '../../../../../Common/Hooks/usePrevious';
import { asyncGetOpcoesSelect } from '../../Requests';
import { buscarOption, converterRegistrosParaOptions, filtrarOpcoesRepetidas } from '../../Util/functions';
import { removerCaracteresInvalidosRsql } from '../../../../../Common/Rsql';
import {
	buscarDisabledDeAcordoComAsPermissoes,
	buscarHiddenDeAcordoComAsPermissoes,
} from '../../../../../Common/Autorizacao/ManipulacaoDeComponentes';
import propTypes from 'prop-types';
import Select from 'react-select';
import Col from '../../../../Col';
import If from '../../../../If';
import Button from '../../../../Button';
import { renderizarValidacao } from '../../../../../Common/TratamentoDeErro/validacoesDeCampos';
import { usuarioPossuiModulos } from '../../../../../Common/Autenticacao';
import { formatarNcm } from '../../../../../Common/Mascara';
import { usePrevious as customUsePrevious, useEffectOnce, useUpdateEffect } from 'react-use';
import { colors } from 'Common';
const customStyles = {
	control: (provided, state) => ({
		...provided,
		height: 32,
		border:
			state.selectProps.errors && state.selectProps.touched ? `1px solid ${colors.vermelho}` : '1px solid #ced4da',
		backgroundColor: colors.branco,
		opacity: state.isDisabled ? '0.5' : '1',
		...dot(state.selectProps.errors && state.selectProps.touched),
	}),
	menu: (base) => ({
		...base,
		marginTop: 0,
		marginBottom: 0,
	}),
	menuPortal: (base) => ({
		...base,
		zIndex: 9999,
	}),
	option: (base, state) => ({
		...base,
		":active" :{...base[":active"], backgroundColor: colors.principalHoverTable},
		backgroundColor: state.isSelected ? colors.principal : state.isFocused ? colors.principalHoverTable : colors.branco,
	}),
	multiValueLabel: (styles, state) => {
		return state.data.isFixed
			? { ...styles, fontWeight: 'bold', color: 'white', paddingRight: 6 }
			: {
					...styles,
					width: state.data.label && state.data.label.length >= 20 ? '225px' : '100%',
					wordWrap: 'break-word',
					textOverflow: 'ellipsis',
				};
	},
	indicatorsContainer: (styles, state) => ({
		...styles,
		marginTop: '-2px',
	}),
};

const dot = (errors) => ({
	':hover': {
		border: errors ? `1px solid ${colors.vermelho} !important` : `1px solid ${colors.principal} !important`,
		cursor: 'pointer',
	},
});

const buttonStyle = {
	width: '23px',
	height: '23px',
	borderRadius: '3px',
	color: colors.principal,
	border: 'unset',
	backgroundColor: colors.brancoTransparente,
	borderColor: colors.brancoTransparente,
	fontSize: '15px',
	boxShadow: 'none',
	margin: 'unset',
};

export function InternalSingleSelect(props) {
	const {
		value,
		modulosEspecificos,
		loadInitialValues,
		montarLabel,
		buscarUrlPesquisa,
		podeVisualizar,
		hidden,
		colStyle,
		obrigatorio,
		label,
		shortcut,
		helpMessage,
		labelSize,
		sm,
		md,
		lg,
		xl,
		isClearable,
		onClickModal,
		esconderBotao,
		errors,
		warnings,
		touched,
		botaoColor,
		botaoIcon,
		podeInserir,
		podeEditar,
		estadoCadastro,
		disabled,
		placeholder,
		disabledButton,
		titleBotaoNovo,
		isSearchable,
		isMobile,
		formatOptionLabel,
		customOptionStyle,
		id,
		resetPesquisa,
		setResetPesquisa,
		onMenuOpen,
		styleButton,
		menuPortalTarget = document.body,
		converterOptions,
		labelStyle,
		optionsRemover,
		resolveBugDataTable = false,
		onKeyDown,
	} = props;

	const shortcutStyle = {
		marginLeft: '4px',
		fontSize: 10,
	};

	const InternalSingleSelectRef = useRef(null);

	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [validouModulo, setValidouModulo] = useState(true);
	const [timeOutPesquisa, setTimeOutPesquisa] = useState(null);
	const [pageSelecionada, setPageSelecionada] = useState(0);
	const [pageSelecionadaPesquisaEspecifica, setPageSelecionadaPesquisaEspecifica] = useState(0);
	const [totalPages, setTotalPages] = useState(null);
	const [totalPagesPesquisaEspecifica, setTotalPagesPesquisaEspecifica] = useState(null);
	const [buscouTodosOsRegistros, setBuscouTodosOsRegistros] = useState(false);
	const afterFirstRenderRef = useRef(false);
	const componentMounted = useRef(true);
	const prevProps = usePrevious(props);
	const [localBuscarUrl, setLocalBuscarUrl] = useState(buscarUrlPesquisa());
	const desabilitarSelect = buscarDisabledDeAcordoComAsPermissoes(podeInserir, podeEditar, estadoCadastro, disabled);
	const [isOpenMenu, setIsOpenMenu] = useState(false);

	const desabilitarBotao = disabledButton || desabilitarSelect;
	const searchableSelect = isSearchable !== undefined ? isSearchable : totalPages > 1 || !isMobile;
	const placeholderSelect = !searchableSelect && placeholder === 'Digite para pesquisar' ? '' : placeholder;

	useEffect(() => {
		setValidouModulo(usuarioPossuiModulos(modulosEspecificos));
		if (value) {
			atribuirNovaOpcao(value);
		}

		if (componentMounted.current) {
			if (validouModulo && loadInitialValues) {
				buscarRegistros('', pageSelecionada);
			}
		}

		if (afterFirstRenderRef) {
			if (prevProps?.resetarPesquisa !== props.resetarPesquisa) {
				resetarPesquisa();
			}
			if (value && prevProps?.value !== value) {
				atribuirNovaOpcao(value);
			}
		} else afterFirstRenderRef = true;

		return () => {
			componentMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (isOpenMenu) {
			buscarRegistros('', pageSelecionada);
			setIsOpenMenu(false);
		}
	}, [isOpenMenu]);

	useUpdateEffect(() => {
		if (buscarUrlPesquisa() !== localBuscarUrl) {
			setLocalBuscarUrl(buscarUrlPesquisa());
		}
	}, [buscarUrlPesquisa]);

	useUpdateEffect(() => {
		resetarPesquisa();
	}, [localBuscarUrl]);

	useUpdateEffect(() => {
		if (resetPesquisa) {
			resetarPesquisa();
		}
	}, [resetPesquisa]);

	function resetarPesquisa() {
		asyncGetOpcoesSelect(buscarUrlPesquisa('', 0), ({ data: registros }) => {
			let newOptions = converterRegistrosParaOptions(registros.content, montarLabel);
			if (typeof converterOptions === 'function') {
				novasOptions = converterOptions(novasOptions);
			}
			atualizarOptions(newOptions);
			setPageSelecionada(0);
			setPageSelecionadaPesquisaEspecifica(0);
			if (resetPesquisa) {
				setResetPesquisa(false);
			}
		});
	}

	function atribuirNovaOpcao(novaOpcao) {
		const options = buscarOptions();
		if (!buscarOption(options, novaOpcao)) {
			atualizarOptions([...options, novaOpcao]);
		}
	}

	function buscarRegistros(inputValue, pageSelecionada) {
		if (componentMounted.current) {
			setLoading(true);
		}

		asyncGetOpcoesSelect(
			buscarUrlPesquisa(removerCaracteresInvalidosRsql(inputValue), pageSelecionada),
			({ data: registros }) => {
				if (componentMounted.current) {
					const options = buscarOptions();
					let novasOptions = converterRegistrosParaOptions(registros.content, montarLabel);

					if (typeof converterOptions === 'function') {
						novasOptions = converterOptions(novasOptions);
					}

					if (inputValue) {
						atualizarOptions(filtrarOpcoesRepetidas(options, novasOptions));
						setPageSelecionadaPesquisaEspecifica(pageSelecionada);
						setTotalPagesPesquisaEspecifica(registros.totalPages);
					} else {
						atualizarOptions(filtrarOpcoesRepetidas(options, novasOptions));
						setPageSelecionada(pageSelecionada);
						setTotalPages(registros.totalPages);
						setBuscouTodosOsRegistros(registros.totalPages === pageSelecionada + 1);
					}
					setTimeout(() => {
						if (componentMounted.current) {
							setLoading(false);
						}
					}, 200);
				}
			},
			() => {
				if (componentMounted.current) {
					setLoading(false);
				}
			}
		);
	}

	function onInputChange(inputValue) {
		if (inputValue && !buscouTodosOsRegistros) {
			clearTimeout(timeOutPesquisa);
			setInputValue(inputValue);
			setTimeOutPesquisa(
				setTimeout(() => {
					buscarRegistros(inputValue, 0);
				}, 500)
			);
		} else {
			if (props.name === 'ncmOrigem') {
				setInputValue(formatarNcm(inputValue));
			} else {
				setInputValue(inputValue);
			}
		}
	}

	function onScrollToBottom() {
		if (!buscouTodosOsRegistros) {
			const page = pageSelecionada + 1;
			const pageEspecifica = pageSelecionadaPesquisaEspecifica + 1;
			if (inputValue) {
				if (totalPagesPesquisaEspecifica && pageEspecifica < totalPagesPesquisaEspecifica) {
					buscarRegistros(inputValue, pageEspecifica);
				}
			} else {
				if (totalPages && page < totalPages) {
					buscarRegistros(inputValue, page);
				}
			}
		}
	}

	function filterOption(e) {
		const input = inputValue.toLowerCase();

		const labelEstaNoInput = e?.label?.toLowerCase().includes(input);
		const codigoBarrasEstaNoInput = e?.data?.codigoBarras?.toString().toLowerCase().includes(input);

		return codigoBarrasEstaNoInput || labelEstaNoInput;
	}

	function getColStyle() {
		if (buscarHiddenDeAcordoComAsPermissoes(podeVisualizar, hidden)) {
			return { display: 'none', ...colStyle };
		}
		return colStyle;
	}

	function montarTitulo() {
		if (obrigatorio) {
			return (
				<span style={{ display: 'flex', alignItems: 'center' }}>
					<label style={{ fontSize: labelSize }} title={helpMessage}>
						{label}
						<b
							style={{
								fontSize: labelSize ? labelSize : '18px',
								lineHeight: '5px',
							}}
						>
							*
						</b>
					</label>
					{shortcut && <span style={{ ...shortcutStyle }}>{shortcut}</span>}
				</span>
			);
		}
		return (
			<label
				style={{
					display: 'flex',
					alignItems: 'center',
					fontSize: labelSize,
					...labelStyle,
				}}
				title={helpMessage}
			>
				{label}
				{shortcut && <span style={{ ...shortcutStyle }}>{shortcut}</span>}
			</label>
		);
	}

	function atualizarOptions(novasOptions) {
		if (props.options) {
			props.onChangeOptions(novasOptions);
		} else {
			setOptions(novasOptions);
		}
		if (props.getOptions) {
			props.getOptions(novasOptions);
		}
	}

	function buscarOptions() {
		if (props.options) {
			return props.options;
		} else {
			if (optionsRemover?.length > 0) {
				return options?.filter((option) => {
					return !optionsRemover?.some((optionRemover) => {
						return optionRemover.idRegistro === option.value;
					});
				});
			} else {
				return options;
			}
		}
	}

	if (!validouModulo) return null;

	return (
		<Col sm={sm} md={md} lg={lg} xl={xl} style={getColStyle()}>
			{montarTitulo()}
			<div className="p-inputgroup">
				<Select
					ref={InternalSingleSelectRef}
					menuPosition="fixed"
					menuPortalTarget={menuPortalTarget}
					className="custom-select"
					id={id}
					isLoading={loading}
					inputValue={inputValue}
					isClearable={isClearable}
					options={buscarOptions()}
					closeMenuOnSelect={true}
					onInputChange={onInputChange}
					isDisabled={desabilitarSelect}
					{...props}
					placeholder={placeholderSelect}
					styles={{ ...customStyles, ...props.style }}
					value={value}
					onChange={props.onChange}
					customOptionStyle={{ ...customOptionStyle, zIndex: '10' }}
					onMenuScrollToBottom={onScrollToBottom}
					formatOptionLabel={formatOptionLabel}
					filterOption={filterOption}
					isSearchable={searchableSelect}
					onKeyDown={(event) => {
						if (resolveBugDataTable) {
							if (onKeyDown) {
								onKeyDown(event, inputValue);
							}
							if (!isOpenMenu && inputValue.length == 0) {
								InternalSingleSelectRef.current.blur();
								InternalSingleSelectRef.current.focus();
							}
						}
					}}
					onMenuOpen={() => {
						onMenuOpen;
						setIsOpenMenu(true);
					}}
					closeMenuOnScroll={(e) => {
						if (e.target.className?.includes('reactSelect__menu-list')) {
							return false;
						} else {
							return true;
						}
					}}
				/>
				<If test={!esconderBotao}>
					<Button
						icon={botaoIcon}
						title={titleBotaoNovo ? titleBotaoNovo : 'Inserir um novo registro'}
						style={{ ...buttonStyle, ...styleButton }}
						styleContentButton={{
							display: 'flex',
							alignItems: 'center',
						}}
						disabled={desabilitarBotao}
						color={botaoColor ? `button-add-icon-only ${botaoColor}` : 'button-add-icon-only'}
						onClick={onClickModal}
						tabIndex={-1}
					/>
				</If>
			</div>
			{renderizarValidacao(errors, touched, warnings)}
		</Col>
	);
}

InternalSingleSelect.defaultProps = {
	noOptionsMessage: () => 'Nenhum elemento encontrado',
	loadingMessage: () => 'Por favor, aguarde...',
	menuPlacement: 'auto',
	className: 'react-select-base',
	classNamePrefix: 'reactSelect',
	podeVisualizar: true,
	botaoColor: 'primary',
	botaoIcon: 'fa fa-plus',
	isClearable: true,
	placeholder: 'Digite para pesquisar',
	loadInitialValues: false,
};

InternalSingleSelect.propTypes = {
	/** Evento disparado ao modificar o valor do componente */
	onChange: propTypes.func,
	/** Passa as options personalizadas para o componente caso necessário. */
	options: propTypes.array,
	/** Funcao para atualizar as options passadas por parâmetro.*/
	onChangeOptions: propTypes.func,
	/** Label do componente */
	label: propTypes.string,
	/** Placeholder do componente */
	placeholder: propTypes.string,
	/** Valor do componente do componente (UUID)*/
	value: propTypes.any,
	/** Nome da classe do componente */
	className: propTypes.string,
	/** Prefixo do nome da classe do componente e das classes filhas */
	classNamePrefix: propTypes.string,
	/** Define se o componente está desabilitado*/
	disabled: propTypes.bool,
	/** Define se o botão do componente está desabilitado*/
	disabledButton: propTypes.bool,
	/** Tamanho padrão da coluna utilizado em dispositivos muito pequenos (0 a 12) */
	col: propTypes.string,
	/** Tamanho do campo em small devices*/
	sm: propTypes.string,
	/** Tamanho do campo em medium devices*/
	md: propTypes.string,
	/** Tamanho do campo em large devices*/
	lg: propTypes.string,
	/** Tamanho do campo em extra large devices*/
	xl: propTypes.string,
	/** Estilo da coluna*/
	colStyle: propTypes.object,
	/** Diz se o usuário possui permissão de visualizar*/
	podeVisualizar: propTypes.bool,
	/** Diz se o usuário possui permissão de editar*/
	podeEditar: propTypes.bool,
	/** Diz se o usuário possui permissão de excluir*/
	podeInserir: propTypes.bool,
	/** Esconde o componente*/
	hidden: propTypes.bool,
	/** Resetar os options do componente ao inserir novo registro*/
	resetarPesquisa: propTypes.bool,
	/** Campo destinado a uma breve  explicação sobre o campo. Irá renderizar um ícone de pergunta caso a propriedade for alimentada.*/
	helpMessage: propTypes.string,
	/** Função que recebe o parâmetro de pesquisa e deve retornar a URL da API que retornará todos os registros */
	buscarUrlPesquisa: propTypes.any.isRequired,
	/** Função que recebe o registro e deve retornar o label do select */
	montarLabel: propTypes.func.isRequired,
	/** Deve exibir o botão de limpar o select */
	isClearable: propTypes.bool,
	/** OnClick do botão de abrir o modal de inserir registro */
	onClickModal: propTypes.func,
	/** Esconder o botão de abrir o modal de inserir registro  */
	esconderBotao: propTypes.bool,
	/** Cor do botão de abrir o modal  */
	botaoColor: propTypes.string,
	/** Ícone do botão de abrir o modal  */
	botaoIcon: propTypes.string,
	/** CSS customizado para as options do dropdown */
	customOptionStyle: propTypes.object,
	/** Pode ser passado componentes customizados para complementar o select base. Um exemplo é o SingleSelectProduto */
	components: propTypes.object,
	/** Determina se o componente irá buscar ou não as options inicialmente */
	loadInitialValues: propTypes.bool,
	/** Personaliza options antes de atualizar */
	converterOptions: propTypes.func,
};
