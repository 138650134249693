import { Field, useFormikContext } from 'formik';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { AutoProgressBar, Checkbox, Form, FormActions, FormContent, Grid, If } from 'components';

import { ModalEdicaoContasPagar } from 'views/financas/ContasPagar/components/ModalEdicaoContasPagar';
import { ModalExclusaoContasPagar } from 'views/financas/ContasPagar/components/ModalExclusaoContasPagar';
import { ActionButtons } from './components/ActionsButtons';
import { FormBodyContaPagar } from './components/FormBodyContasPagar';
import { FormCompensacaoAutomaticaETaxa } from './components/FormCompensacaoAutomaticaETaxa';
import { FormInformacoesComplementares } from './components/FormInformacoesComplementares';
import { FormMultiplasParcelas } from './components/FormMultiplasParcelas';
import { FormNovoPagamento } from './components/FormNovoPagamento';
import { FormTipoContaPagar } from './components/FormTipoContaPagar';
import { FormValorPago } from './components/FormValorPago';
import { TIPO_CONTA_PAGAR } from './Util/constantes';

function FormPagar({
	isMobile,
	isModal,
	onHideModal,
	history,
	onNovoClick,
	onHideModalExclusao,
	onHideModalEdicao,
	atribuirValoresIniciais,
	buscarValor,
	podeEditar,
	setRegistroSelecionado,
	exibirModalExclusao,
	setExibirModalExclusao,
	exibirModalConfirmacaoEdicao,
	setExibirModalConfirmacaoEdicao,
	activeIndex,
	setActiveIndex,
	filialConectada,
	informacoesPermissoes,
	isEdicao,
	isReparcelamento = false,
	isReparcelada = false,
	origem,
	disabledFieldReparcelamento,
}) {
	const { values, setFieldValue } = useFormikContext();

	return (
		<>
			<AutoProgressBar />
			<Form>
				<If test={!isReparcelamento}>
					<FormActions>
						<ActionButtons
							onHide={onHideModal}
							filialConectadaId={filialConectada.id}
							{...{
								informacoesPermissoes,
								isModal,
								isMobile,
								setExibirModalExclusao,
								onNovoClick,
								history,
								setRegistroSelecionado,
								atribuirValoresIniciais,
								setExibirModalConfirmacaoEdicao,
								isReparcelada,
								origem,
							}}
						/>
					</FormActions>
				</If>
				<FormContent>
					<Grid>
						<FormBodyContaPagar
							{...{ informacoesPermissoes, isReparcelamento, isReparcelada, disabledFieldReparcelamento }}
						/>

						<If test={!isEdicao}>
							<FormTipoContaPagar {...{ isReparcelamento }} />
							<If test={values.tipo !== TIPO_CONTA_PAGAR.UNICA}>
								<FormMultiplasParcelas {...{ informacoesPermissoes, buscarValor }} />
							</If>
						</If>

						<If test={!isReparcelamento}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								component={Checkbox}
								colStyle={{ marginTop: '10px' }}
								label={
									values.tipo === TIPO_CONTA_PAGAR.VALOR_PARCELADO ? 'Valor primeira parcela já pago' : 'Valor já pago'
								}
								name="pago"
								hidden={isEdicao}
								onChange={() => {
									setFieldValue('pago', !values.pago);
									if (!values.pago) {
										setFieldValue('valorPago', buscarValor());
									}
								}}
								checked={values.pago}
								{...informacoesPermissoes}
							/>

							<If test={values.pago}>
								<FormValorPago {...{ informacoesPermissoes }} />
							</If>
						</If>

						<If test={isEdicao}>
							<FormNovoPagamento
								{...{
									isMobile,
									podeEditar,
									isReparcelamento,
									informacoesPermissoes,
								}}
							/>
						</If>

						<Accordion
							className="tab-accordion-lite"
							style={{ marginTop: '10px' }}
							activeIndex={activeIndex}
							onTabChange={(event) => setActiveIndex(event.index)}
							multiple
						>
							<AccordionTab header="Compensação e taxas">
								<FormCompensacaoAutomaticaETaxa {...{ informacoesPermissoes, isReparcelada }} />
							</AccordionTab>

							<AccordionTab header="Informações complementares">
								<FormInformacoesComplementares {...{ informacoesPermissoes, isReparcelada }} />
							</AccordionTab>
						</Accordion>
					</Grid>
				</FormContent>
			</Form>
			<If test={exibirModalExclusao}>
				<ModalExclusaoContasPagar visible={exibirModalExclusao} onHide={onHideModalExclusao} />
			</If>
			<If test={exibirModalConfirmacaoEdicao}>
				<ModalEdicaoContasPagar visible={exibirModalConfirmacaoEdicao} onHide={onHideModalEdicao} />
			</If>
		</>
	);
}

export default FormPagar;
