import { useState } from 'react';
import { useFormikContext } from 'formik';
import { permissoes, recursos, usuarioPossuiPermissao } from 'Common';

import ModalObjetoDeServico from 'views/cadastros/servicos/ObjetoDeServico/Modal';
import SingleSelect from '../SingleSelect';

function SingleSelectObjetoServico(props) {
	const { service, onChange, field, cliente, url } = props;
	const { setFieldValue } = useFormikContext();

	const [visible, setVisible] = useState(false);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.SERVICOS, permissoes.INSERIR));
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.SERVICOS, permissoes.VISUALIZAR));
	const [resetPesquisa, setResetPesquisa] = useState(false);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		let query = `?query=nome=contains="*${pesquisa}*";situacao=="ATIVO"&page=${page}&size=50`;
		if (cliente) {
			query = `?query=nome=contains="*${pesquisa}*";cliente.id==${cliente.value};situacao=="ATIVO"&page=${page}&size=50`;
		}
		return `${url}${query}`;
	}

	function buscarUrlRegistroUnico(id) {
		const query = `?query=id==${id}`;
		return `${url}${query}`;
	}

	function montarLabel(row) {
		return `${row.codigo} - ${row.nome}`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			onChange({
				label: montarLabel(novoRegistro),
				value: novoRegistro.id,
				registro: { ...novoRegistro, cliente: { ...novoRegistro.cliente, id: novoRegistro.cliente.value } },
			});
			setResetPesquisa(true);
		}
	}

	function esconderModal() {
		setVisible(false);
	}

	function handleChange(value) {
		if (onChange) {
			onChange(value);
		} else {
			setFieldValue(field.name, {
				value: value.value,
				label: value.label,
				registro: value.registro,
			});
		}
	}

	return (
		<>
			<ModalObjetoDeServico service={service} visible={visible} onHide={onHide} cliente={cliente} />
			<SingleSelect
				{...props}
				buscarUrlPesquisa={buscarUrlPesquisa}
				buscarUrlRegistroUnico={buscarUrlRegistroUnico}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				onChange={handleChange}
				disabledButton={!podeInserirEVisualizar}
				titleBotaoNovo={
					podeInserirEVisualizar
						? 'Adicionar novo objeto de serviço'
						: 'Você não possui permissão para executar esta ação'
				}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

SingleSelectObjetoServico.defaultProps = {
	label: 'Objeto de serviço',
	isClearable: false,
};

export default SingleSelectObjetoServico;
