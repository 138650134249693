import { useEffect, useState } from 'react';

import { services } from '../../../Common/Constantes/api';
import SingleSelect from '../SingleSelect';
import ModalOperacaoFiscal from '../../../views/cadastros/fiscal/OperacaoFiscal/Modal';

export default function SingleSelectOperacaoFiscal(props) {
	const { label, isClearable, touched, isMobile, isTablet, service, setHideBackground, tipoOperacao, tipoCliente } =
		props;
	const [visible, setVisible] = useState(false);
	const [resetPesquisa, setResetPesquisa] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);

		return () => {
			setIsMounted(false);
		};
	}, []);

	useEffect(() => {
		buscarUrlPesquisa();
	}, [tipoOperacao, tipoCliente]);

	useEffect(() => {
		if (setHideBackground) {
			setHideBackground(visible);
		}
	}, [visible]);

	function buscarUrlPesquisa(pesquisa, page) {
		let query = `?query=(descricao=contains="*${pesquisa}*",codigo=contains="${pesquisa}*");situacao=="ATIVO";indicacaoMovimento==${tipoOperacao};vendaConsumidor==${tipoCliente}&page=${page}&size=50`;
		if (tipoCliente === undefined || tipoCliente === null) {
			query = `?query=(descricao=contains="*${pesquisa}*",codigo=contains="${pesquisa}*");situacao=="ATIVO";indicacaoMovimento==${tipoOperacao}&page=${page}&size=50`;
		}
		if (!tipoOperacao && !tipoCliente) {
			query = `?query=(descricao=contains="*${pesquisa}*",codigo=contains="${pesquisa}*");situacao=="ATIVO"&page=${page}&size=50`;
		}
		return `${services.GESTOR}/v1/tributacoes/operacoes_fiscais/resumo${query}`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		return row.codigo + ' - ' + row.descricao;
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro?.id && isMounted) {
			props.onChange(
				{
					label: montarLabel({
						codigo: novoRegistro.codigo,
						descricao: novoRegistro.descricao,
					}),
					registro: {
						id: novoRegistro.id,
						codigo: novoRegistro.codigo,
						descricao: novoRegistro.descricao,
						situacao: novoRegistro.situacao,
					},
					value: novoRegistro.id,
				},
				props.index
			);
			setResetPesquisa(true);
		}
	}

	return (
		<>
			<ModalOperacaoFiscal service={service} visible={visible} onHide={onHide} />
			<SingleSelect
				{...props}
				titleBotaoNovo={'Adicionar nova operação fiscal'}
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				label={label}
				onChange={props.onChange}
				isClearable={isClearable}
				errors={props.errors}
				useFormErrors={false}
				useFormTouched={false}
				touched={touched}
				styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
				menuPortalTarget={document.body}
				placeholder={isMobile || isTablet ? 'Selecione' : 'Digite para pesquisar'}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

SingleSelectOperacaoFiscal.defaultProps = {
	label: 'Operação fiscal',
};
