import { useState } from 'react';

import SingleSelect from '../SingleSelect';
import { ModalComponenteValorPrestacao } from 'views/cadastros/transporte/ComponenteValorPrestacao/Modal';

export default function SingleSelectComponenteValorPrestacao(props) {
	const [visible, setVisible] = useState(false);
	const [resetPesquisa, setResetPesquisa] = useState(false);

	function buscarUrlPesquisa(pesquisa, page) {
		return `${props.url}?query=(descricao=contains="*${pesquisa}*");situacao=="ATIVO"&page=${page}&size=50&sort=descricao`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		return row.descricao;
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro?.id) {
			props.onChange({
				label: novoRegistro.descricao,
				registro: novoRegistro,
				value: novoRegistro.id,
			});
			setResetPesquisa(true);
		}
	}

	return (
		<>
			<ModalComponenteValorPrestacao visible={visible} onHide={onHide} />
			<SingleSelect
				{...props}
				titleBotaoNovo={'Adicionar novo componente'}
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				onChange={props.onChange}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

SingleSelectComponenteValorPrestacao.defaultProps = {
	label: 'Componente do valor da prestação',
};
