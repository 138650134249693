import SingleSelect from '../SingleSelect';
import { useState } from 'react';
import propTypes from 'prop-types';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import ModalCategoria from '../../../views/cadastros/financas/Categorias/components/ModalCategoria';
import If from '../../If';

export default function SingleSelectRegiao(props) {
	const { url, disabledButton, esconderBotao } = props;

	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_REGIOES, permissoes.INSERIR));
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.CADASTROS_REGIOES, permissoes.VISUALIZAR));
	const [resetPesquisa, setResetPesquisa] = useState(false);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO"&page=${page}&size=25&sort=nome,asc`;
	}

	function buscarUrlRegistroUnico(id) {
		return `${url}?query=id==${id}`;
	}

	function montarLabel(row) {
		return row.nome;
	}

	return (
		<>
			<SingleSelect
				{...props}
				buscarUrlPesquisa={buscarUrlPesquisa}
				buscarUrlRegistroUnico={buscarUrlRegistroUnico}
				montarLabel={montarLabel}
				esconderBotao={esconderBotao}
				disabledButton={!podeInserirEVisualizar || disabledButton}
				titleBotaoNovo={
					podeInserirEVisualizar && !disabledButton
						? 'Adicionar nova região'
						: 'Você não possui permissão para executar esta ação'
				}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

SingleSelectRegiao.defaultProps = {
	label: 'Região',
	disabledButton: false,
};

SingleSelectRegiao.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
};
