import propTypes from 'prop-types';
import { useState } from 'react';
import { ModalVendedores } from 'views/cadastros/vendas/Vendedores/Modal';
import { copiarObjeto } from '../../../Common';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { permissoes, recursos } from '../../../Common/Constantes/autorizacao';
import MultipleSelect from '../MultipleSelect';
import { montarLabelPessoa } from '../SingleSelectPessoa/Util';

export const tipoVendedorfiltro = {
	VENDEDOR: 'VENDEDOR',
	SUPERVISOR: 'SUPERVISOR',
	GERENTE: 'GERENTE',
};

export const tipoBuscaVendedorFiltro = {
	SUPERIOR: 'SUPERIOR',
	DESCENDENTE: 'DESCENDENTE',
};

export default function MultiSelectVendedores(props) {
	const { url, tipoVendedor, tipoBusca, noOptionsMessage, service } = props;

	const [visible, setVisible] = useState(false);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR));
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.VISUALIZAR));
	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarTipoFiltroBusca() {
		if (tipoVendedor === tipoVendedorfiltro.VENDEDOR && tipoBusca === tipoBuscaVendedorFiltro.SUPERIOR) {
			return tipoVendedorfiltro.SUPERVISOR;
		} else if (tipoVendedor === tipoVendedorfiltro.SUPERVISOR) {
			if (tipoBusca === tipoBuscaVendedorFiltro.SUPERIOR) {
				return tipoVendedorfiltro.GERENTE;
			} else {
				return tipoVendedorfiltro.VENDEDOR;
			}
		} else if (tipoVendedor === tipoVendedorfiltro.GERENTE && tipoBusca === tipoBuscaVendedorFiltro.DESCENDENTE) {
			return tipoVendedorfiltro.SUPERVISOR;
		}

		return tipoVendedorfiltro.VENDEDOR;
	}

	function buscarUrlPesquisa(pesquisa, page) {
		const tipoFiltroBusca = buscarTipoFiltroBusca();

		switch (tipoFiltroBusca) {
			case tipoVendedorfiltro.SUPERVISOR: {
				return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO";tipo=="SUPERVISOR"&page=${page}&size=50&sort=nome,asc`;
			}
			case tipoVendedorfiltro.GERENTE: {
				return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO";tipo=="GERENTE"&page=${page}&size=50&sort=nome,asc`;
			}
			case tipoVendedorfiltro.VENDEDOR: {
				return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO";tipo=="VENDEDOR"&page=${page}&size=50&sort=nome,asc`;
			}
			default: {
				return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO";tipo=="VENDEDOR"&page=${page}&size=50&sort=nome,asc`;
			}
		}
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	async function setarNovoRegistroNoSelect(novoRegistro) {
		const partes = await copiarObjeto([...props.value]);
		if (novoRegistro) {
			partes.push({
				label: montarLabel(novoRegistro),
				value: novoRegistro.id,
				registro: {
					codigo: novoRegistro.codigo,
					cpf: novoRegistro.cpf,
					cnpj: novoRegistro.cnpj,
					id: novoRegistro.id,
					nome: novoRegistro.nome,
				},
			});

			await props.onChange(partes);
		}
	}

	function montarLabel(row) {
		return montarLabelPessoa(row);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	return (
		<>
			<ModalVendedores service={service} visible={visible} onHide={onHide} />
			<MultipleSelect
				{...props}
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				noOptionsMessage={noOptionsMessage}
				disabledButton={!podeInserirEVisualizar}
				onChange={props.onChange}
				esconderBotao={false}
			/>
		</>
	);
}

MultiSelectVendedores.propTypes = {
	url: propTypes.string.isRequired,
	onChange: propTypes.func.isRequired,
	label: propTypes.string,
};

MultiSelectVendedores.defaultProps = {
	label: 'Parte',
};
