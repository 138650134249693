import { useState } from 'react';
import { useFormikContext } from 'formik';

import { colors, copiarObjeto, estadosCadastro, formatarMonetario, gerarUUID, removerElemento } from 'Common';
import { ButtonNovo, Divider, Fieldset, If } from 'components';

import ModalPagamento from '../ModalPagamento';
import TabelaPagamentos from '../TabelaPagamentos';

function FormNovoPagamento({ informacoesPermissoes, podeEditar, isMobile }) {
	const { values, errors, setFieldValue } = useFormikContext();
	const [registroSelecionado, setRegistroSelecionado] = useState(null);
	const [exibirModalPagamento, setExibirModalPagamento] = useState(false);

	const desabilitarCampos = informacoesPermissoes.estadoCadastro === estadosCadastro.EDICAO && !podeEditar;

	const valorAPagar = calcularValorAPagar();
	const tituloPagamentos = buscarTituloPagamentos(valorAPagar, values.valor);

	function novoPagamento() {
		setRegistroSelecionado(null);
		setExibirModalPagamento(true);
	}

	function onHidePagamento() {
		setExibirModalPagamento(false);
	}

	function inserirRegistroNaLista(dadosFormulario) {
		const pagamentos = copiarObjeto(values.pagamentos);
		pagamentos && pagamentos.push(dadosFormulario);

		setFieldValue('pagamentos', pagamentos);
	}

	function atualizarRegistroNaLista(registro) {
		const { pagamentos } = values;
		const result =
			pagamentos &&
			pagamentos.map((item) => {
				if (item.idTemporario && item.idTemporario === registro.idTemporario) {
					return registro;
				} else if (item.id && item.id === registro.id) {
					return registro;
				}
				return item;
			});

		setFieldValue('pagamentos', result);
	}

	function editarPagamento(dadosFormulario) {
		setRegistroSelecionado(dadosFormulario);
		setExibirModalPagamento(true);
	}

	function salvarPagamento(pagamento) {
		const novoPagamento = { ...pagamento };
		novoPagamento.pagamentoNovoOuAlterado = true;

		if (!pagamento.id && !pagamento.idTemporario) {
			novoPagamento.idTemporario = gerarUUID();

			inserirRegistroNaLista(novoPagamento);
		} else {
			atualizarRegistroNaLista(novoPagamento);
		}

		onHidePagamento();
	}

	function excluirPagamento(dadosFormulario) {
		setFieldValue('pagamentos', removerElemento(values.pagamentos, dadosFormulario));
		setRegistroSelecionado(null);
	}

	function calcularValorAPagar() {
		let valorPago = 0;

		values.pagamentos &&
			values.pagamentos.forEach((pagamento) => {
				valorPago += pagamento.valor;
			});

		return values.valor - valorPago;
	}

	function buscarTituloPagamentos(valorAPagar, valorTotal) {
		const totalPagamentos = valorTotal - valorAPagar;
		if (!totalPagamentos) {
			return (
				<Divider
					styleContainer={{ margin: '0.5rem 0.5rem 0.5rem 0rem' }}
					styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
				/>
			);
		} else {
			return (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						paddingBottom: '5px',
					}}
				>
					<Divider
						styleContainer={{ margin: '0.5rem 0.5rem 0.5rem 0rem' }}
						styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
					/>
					<span style={{ fontWeight: 'bold' }}>
						Pago: <b>{formatarMonetario(totalPagamentos)}</b> de {formatarMonetario(valorTotal || 0)}{' '}
					</span>
					<If test={errors.pagamentos}>
						<span style={{ fontSize: '12px', fontWeight: 'lighter', color: colors.vermelho }}>{errors.pagamentos}</span>
					</If>
				</div>
			);
		}
	}

	return (
		<>
			<Fieldset style={{ width: '100%', marginRight: '4px' }} className="fieldset">
				<div style={{ margin: '-20px 5px 0 5px' }}>{tituloPagamentos}</div>
				<ButtonNovo label="Novo pagamento" podeInserir={!desabilitarCampos} onClick={(e) => novoPagamento(e)} />
				<TabelaPagamentos
					podeEditar={!desabilitarCampos}
					podeExcluir={!desabilitarCampos}
					registros={values.pagamentos}
					onEditar={editarPagamento}
					onExcluir={excluirPagamento}
					isMobile={isMobile}
				/>
			</Fieldset>
			<If test={exibirModalPagamento}>
				<ModalPagamento
					isModal
					visible
					onSalvar={salvarPagamento}
					onHide={onHidePagamento}
					valorAPagar={valorAPagar}
					formaPagamento={values.formaPagamento}
					conta={values.conta}
					informacoesPermissoes={informacoesPermissoes}
					registroSelecionado={registroSelecionado}
					setRegistroSelecionado={setRegistroSelecionado}
				/>
			</If>
		</>
	);
}

export { FormNovoPagamento };
