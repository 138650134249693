import { services } from 'Common';
import MultipleSelect from '../MultipleSelect';

export default function MultipleSelectEstados(props) {
	function buscarUrlPesquisa(pesquisa, page) {
		return `${services.GESTOR}/v1/estados/resumo?query=nome=contains="*${pesquisa}*",sigla=contains="*${pesquisa}*"&page=${page}&size=50&sort=nome`;
	}

	function montarLabel(row) {
		if (row) {
			return row.nome;
		}
	}

	return (
		<>
			<MultipleSelect
				{...props}
				montarLabel={montarLabel}
				buscarUrlPesquisa={buscarUrlPesquisa}
				onChange={props.onChange}
			/>
		</>
	);
}

MultipleSelectEstados.defaultProps = {
	label: 'Setores ',
};
