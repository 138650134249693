function converterComponentesValorPrestacaoParaCalculadoraFrete(data) {
	if (data) {
		return data.map((item) => {
			return {
				label: item.componente.label,
				registro: { ...item?.componente?.registro, valor: item.valor },
				value: item.tempId || item.id,
			};
		});
	}

	return [];
}

function converterComponenteValorPrestacaoInicial(componente) {
	return {
		label: componente.componente.label,
		registro: { ...componente?.componente?.registro, valor: componente.valor },
		value: componente.tempId || componente.id,
	};
}

export { converterComponentesValorPrestacaoParaCalculadoraFrete, converterComponenteValorPrestacaoInicial };
