import MultipleSelect from '../MultipleSelect';
import propTypes from 'prop-types';

export default function MultipleSelectContas(props) {
	function buscarUrlPesquisa(pesquisa, page) {
		return `${props.url}?query=nome=contains="*${pesquisa}*"&page=${page}&size=50&sort=nome`;
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			props.onChange(novoRegistro);
		}
	}

	function montarLabel(row) {
		if (row) {
			return row.nome;
		}
	}

	return (
		<>
			<MultipleSelect
				{...props}
				montarLabel={montarLabel}
				buscarUrlPesquisa={buscarUrlPesquisa}
				onChange={props.onChange}
			/>
		</>
	);
}

MultipleSelectContas.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/papeis`*/
	url: propTypes.string.isRequired,
	// Define se p campo é obrigatório no formulário
	obrigatorio: propTypes.bool,
};

MultipleSelectContas.defaultProps = {
	label: 'Grupos de usuários',
	obrigatorio: false,
};
