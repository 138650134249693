import { useState } from 'react';
import autoBind from 'react-autobind';
import SingleSelect from '../SingleSelect';
import { services } from '../../../Common/Constantes/api';
import { permissoes, recursos } from '../../../Common/Constantes/autorizacao';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import ModalPlanosAdministracao from '../../../views/administracao/Planos/Modal';

function SingleSelectPlano(props) {
	const [visible, setVisible] = useState(false);
	const [podeInserir, setPodeInserir] = useState(
		usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.INSERIR)
	);
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.PLANOS_ADMINISTRACAO, permissoes.VISUALIZAR)
	);

	function buscarUrlPesquisa(pesquisa, page) {
		return `${props.url}?query= aplicacao=="${props.aplicacao}";descricao=contains="*${pesquisa}*"&page=${page}&size=50`;
	}

	function montarLabel(row) {
		return row.descricao;
	}

	function mostrarModal() {
		setVisible(true);
	}

	function esconderModal() {
		setVisible(false);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			props.onChange({
				label: montarLabel(novoRegistro),
				value: novoRegistro.id,
			});
		}
	}

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	return (
		<>
			<ModalPlanosAdministracao service={services.GESTOR} visible={visible} onHide={onHide} />
			<SingleSelect
				{...props}
				titleBotaoNovo="Adicionar novo plano"
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				name={props.field.name}
				disabledButton={!podeInserirEVisualizar}
				onChange={props.onChange}
			/>
		</>
	);
}

SingleSelectPlano.defaultProps = {
	label: 'Plano',
};

export default SingleSelectPlano;
