import { useState } from 'react';
import ModalPessoas from '../../../views/cadastros/Pessoas/Modal';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { montarLabelPessoa } from './Util';
import { removerCaracteres } from '../../../Common/ManipulacaoDeString';
import { services } from '../../../Common';
import { TipoPessoa } from '../../../views/cadastros/Pessoas/Util/constantes';

export default function SingleSelectPessoa(props) {
	const {
		service,
		isMobile,
		label,
		setHideBackground,
		tipoPessoa,
		menuPortalTarget = document.body,
		onShowModal,
	} = props;

	const [visible, setVisible] = useState(false);
	const [url, setUrl] = useState(setUrlPeloTipoPessoa(tipoPessoa));
	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR));
	const [podeVisualizar, setPodeVisualizar] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.VISUALIZAR));
	const [resetPesquisa, setResetPesquisa] = useState(false);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function setUrlPeloTipoPessoa(tipoPessoa) {
		switch (tipoPessoa) {
			case TipoPessoa.CLIENTE:
				return `${services.GESTOR}/v1/pessoas/clientes`;
			case TipoPessoa.FORNECEDOR:
				return `${services.GESTOR}/v1/pessoas/fornecedores`;
			case TipoPessoa.TRANSPORTADOR:
				return `${services.GESTOR}/v1/pessoas/transportadores`;
			default:
				return `${services.GESTOR}/v1/pessoas`;
		}
	}

	function buscarUrlPesquisa(pesquisa, page) {
		const pesquisaCodigo = removerCaracteres(pesquisa?.replaceAll('&', ''), ['.']);
		return `${url}?query=(codigo=contains="*${pesquisaCodigo}*", pessoaFisica.cpf=contains="*${pesquisa?.replaceAll(
			'&',
			'%26'
		)}*", pessoaJuridica.cnpj=contains="*${pesquisa?.replaceAll('&', '%26')}*" ,nome=contains="*${pesquisa?.replaceAll(
			'&',
			'%26'
		)}*");situacao=="ATIVA"&page=${page}&size=20&sort=nome`;
	}

	function mostrarModal() {
		if (setHideBackground) {
			setHideBackground(true);
		}
		if (onShowModal) {
			onShowModal();
		}
		setVisible(!visible);
	}

	function esconderModal() {
		if (setHideBackground) {
			setHideBackground(false);
		}
		setVisible(false);
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			props.onChange({
				label: montarLabel({
					...novoRegistro,
					pessoaJuridica: { cnpj: novoRegistro.cnpj },
					pessoaFisica: { cpf: novoRegistro.cpf },
					identificacao: novoRegistro.identificacao,
				}),
				value: novoRegistro.id,
				registro: {
					...novoRegistro,
					nome: novoRegistro.nome,
					id: novoRegistro.id,
					cnpj: novoRegistro.cnpj,
					cpf: novoRegistro.cpf,
					identificacao: novoRegistro.identificacao,
					configPrecoPraticado: novoRegistro.configPrecoPraticado,
					enderecos: novoRegistro.enderecos,
				},
			});
			setResetPesquisa(true);
		}
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		let pessoa = {
			...row,
			cnpj: row.pessoaJuridica?.cnpj,
			cpf: row.pessoaFisica?.cpf,
		};
		return montarLabelPessoa(pessoa);
	}

	return (
		<>
			<ModalPessoas
				service={service}
				visible={visible}
				onHide={onHide}
				tipoPessoaNovoCadastro={tipoPessoa}
				container={menuPortalTarget}
			/>
			<SingleSelect
				{...props}
				titleBotaoNovo="Adicionar nova pessoa"
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				label={label}
				onChange={props.onChange}
				onClickModal={mostrarModal}
				errors={props.errors}
				useFormErrors={false}
				useFormTouched={false}
				touched={props.touched}
				disabledButton={!podeInserirEVisualizar}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
				menuPortalTarget={menuPortalTarget}
				placeholder={isMobile ? 'Selecione uma opção' : 'Digite para pesquisar'}
			/>
		</>
	);
}

SingleSelectPessoa.defaultProps = {
	label: 'Pessoa',
};
