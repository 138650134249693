import { services } from 'Common';
import MultipleSelect from '../MultipleSelect';

export default function MultipleSelectProdutosGrupo(props) {
	function buscarUrlPesquisa(pesquisa, page) {
		return `${services.GESTOR}/v1/produtos/grupos?query=nivel=contains="${pesquisa}",descricao=contains="*${pesquisa}*"&page=${page}&size=50&sort=nivel`;
	}

	function montarLabel(row) {
		return row.nivel + ' - ' + row.descricao;
	}

	return (
		<>
			<MultipleSelect
				{...props}
				montarLabel={montarLabel}
				buscarUrlPesquisa={buscarUrlPesquisa}
				onChange={props.onChange}
			/>
		</>
	);
}

MultipleSelectProdutosGrupo.defaultProps = {
	label: 'Grupos',
};
