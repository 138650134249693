import { useState } from 'react';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import { validarUUID } from 'Common';

export default function SingleSelectTabelaPreco(props) {
	const { url, disabledButton, idFilialParaPesquisa } = props;
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_VENDEDORES, permissoes.INSERIR));
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.CADASTROS_VENDEDORES, permissoes.VISUALIZAR));
	const [resetPesquisa, setResetPesquisa] = useState(false);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarUrlPesquisa(pesquisa, page) {
		if (validarUUID(idFilialParaPesquisa)) {
			return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO";filial.id=="${idFilialParaPesquisa}"&page=${page}&size=50&sort=nome,asc`;
		}

		return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO"&page=${page}&size=50&sort=nome,asc`;
	}

	function buscarUrlRegistroUnico(id) {
		return `${url}?query=id==${id}`;
	}

	function montarLabel(row) {
		if (row.filialCodigo) {
			const labelFilial = montarLabelFilial(row);
			return `${labelFilial} - ${row.nome}`;
		}

		return row.nome;
	}

	function montarLabelFilial(row) {
		const filialCodigo = row.filialCodigo ? row.filialCodigo : '';
		const filialNome =
			row.filialNomeApresentacao || row.filialNome ? `${row.filialNomeApresentacao || row.filialNome}` : '';

		return `[${filialCodigo} - ${filialNome}]`;
	}

	return (
		<>
			<SingleSelect
				{...props}
				buscarUrlPesquisa={buscarUrlPesquisa}
				buscarUrlRegistroUnico={buscarUrlRegistroUnico}
				montarLabel={montarLabel}
				esconderBotao
				disabledButton={!podeInserirEVisualizar || disabledButton}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

SingleSelectTabelaPreco.defaultProps = {
	label: 'Tabela de preço',
	disabledButton: false,
};

SingleSelectTabelaPreco.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
};
