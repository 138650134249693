import { services } from 'Common/Constantes/api';
import { get, exibirToast, del, put, post } from 'Common/Requisicoes';

export async function asyncBuscarCategorias(url, onSuccess, onError) {
	await get(url, null, onSuccess, onError);
}

export async function buscarCategoria(idCategoria, onSuccess, onError) {
	let resposta = null;
	await get(`${services.GESTOR}/v1/categorias/${idCategoria}`, null, onSuccess, (err) => {
		onError && onError(err);
	});
	return resposta;
}

export async function asyncDeleteCategoria(idCategoria, onSuccess, onError) {
	await del(
		`${services.GESTOR}/v1/categorias/${idCategoria}`,
		null,
		exibirToast(onSuccess, 'Categoria removida'),
		onError
	);
}

export async function asyncInativarCategoria(categoria, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/categorias/${categoria.id}`,
		{ ...categoria, ativa: false },
		null,
		exibirToast(onSuccess, 'Categoria inativada'),
		onError
	);
}

export async function asyncAtivarCategoria(categoria, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/categorias/${categoria.id}`,
		{ ...categoria, ativa: true },
		null,
		exibirToast(onSuccess, 'Categoria ativada'),
		onError
	);
}

export async function alterarNivelCategoria(id, tipoAlteracao, onSuccess, onError) {
	await put(
		`${services.GESTOR}/v1/categorias/${id}/alterar_nivel`,
		{ id: id, tipoAlteracao: tipoAlteracao },
		null,
		onSuccess,
		onError
	);
}

export async function buscarCategoriasTitulosComVinculos(onSuccess, onError) {
	await get(`${services.GESTOR}/v1/categorias/buscas_categorias_titulos_com_lancamentos`, null, onSuccess, onError);
}

export async function atualizarRegistrosComInconsistencias(categorias, onSuccess) {
	await post(
		`${services.GESTOR}/v1/categorias/atualizar_registros_inconsistentes`,
		categorias,
		null,
		exibirToast(onSuccess, 'Registros com categoria inconsistente atualizados')
	);
}
