import { useEffect, useState } from 'react';
import SingleSelect from '../SingleSelect';
import { permissoes, recursos, services, usuarioPossuiPermissao } from 'Common';
import ModalCodigoServicoTributacao from '../../../views/cadastros/fiscal/CodigoServico/Modal';

export default function SingleSelectCodigoServico(props) {
	const {
		onChange,
		listarTributacaoCodigoServico = true,
		listarTributacoesCadastradas = false,
		mostrarWarnning = true,
		esconderBotao = false,
		service,
		mostrarSimbolo,
		value,
	} = props;

	const [visible, setVisible] = useState(false);
	const [resetPesquisa, setResetPesquisa] = useState(false);
	const [isMounted, setIsMounted] = useState(false);
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR)
	);

	useEffect(() => {
		setIsMounted(true);

		return () => {
			setIsMounted(false);
		};
	}, []);

	function mostrarModal() {
		setVisible(!visible);
	}

	function buscarUrlPesquisa(pesquisa, page) {
		let codigoPesquisa = pesquisa?.replaceAll('.', '');
		if (listarTributacaoCodigoServico) {
			return String(`${services.GESTOR}/v1/codigo_servico?`)
				.concat('query=utilizado==false;situacao=="ATIVO";')
				.concat(`(descricao=contains="*${pesquisa}*",`)
				.concat(`codigo=contains="*${codigoPesquisa}*")`)
				.concat(`&page=${page}&size=50&sort=codigo`);
		} else if (listarTributacoesCadastradas) {
			return String(`${services.GESTOR}/v1/codigo_servico?`)
				.concat('query=utilizado==false;situacao=="ATIVO";')
				.concat('cadastrado==true;')
				.concat(`(descricao=contains="*${pesquisa}*",`)
				.concat(`codigo=contains="*${codigoPesquisa}*")`)
				.concat(`&page=${page}&size=50&sort=codigo`);
		} else {
			return String(`${services.GESTOR}/v1/codigo_servico?`)
				.concat('query=utilizado==false;situacao=="ATIVO";')
				.concat(`cadastrado==${listarTributacaoCodigoServico};`)
				.concat(`(descricao=contains="*${pesquisa}*",`)
				.concat(`codigo=contains="*${codigoPesquisa}*")`)
				.concat(`&page=${page}&size=50&sort=codigo`);
		}
	}

	function montarLabel(row) {
		if (mostrarSimbolo) {
			if (row.cadastrado) {
				return '✓ - ' + row.codigo + ' - ' + row.descricao;
			} else {
				return '✕ - ' + row.codigo + ' - ' + row.descricao;
			}
		} else {
			return row.codigo + ' - ' + row.descricao;
		}
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro?.id && isMounted) {
			props.onChange({
				label: montarLabel({
					codigo: novoRegistro.codigoServico?.registro.codigo,
					descricao: novoRegistro.descricao,
					cadastrado: true,
				}),
				registro: {
					id: novoRegistro.id,
					codigo: novoRegistro.codigoServico?.registro.codigo,
					descricao: novoRegistro.descricao,
					situacao: novoRegistro.situacao,
					cadastrado: true,
					utilizado: false,
				},
				value: novoRegistro.id,
			});
			setResetPesquisa(true);
		}
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	return (
		<>
			<ModalCodigoServicoTributacao
				service={service}
				visible={visible}
				onHide={onHide}
				codigoServico={value && value.registro && value.registro.cadastrado === false ? value : null}
			/>
			<SingleSelect
				{...props}
				titleBotaoNovo={'Adicionar novo código de serviço'}
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				esconderBotao={esconderBotao}
				styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
				disabledButton={!podeVisualizar}
				onChange={onChange}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
				warnings={
					mostrarWarnning && value && value.registro && value.registro.cadastrado === false
						? 'Código serviço sem tributação cadastrada'
						: ''
				}
			/>
		</>
	);
}

SingleSelectCodigoServico.defaultProps = {
	label: 'Código serviço',
};
