import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { usuarioPossuiPermissao, recursos, permissoes, services, copiarObjeto } from 'Common';
import { confirm, If, FormActions, Form, FormContent, ButtonNovo, Button } from 'components';

import ModalCategoria from './components/ModalCategoria';
import TreeTabelaCategorias from './components/TreeTabelaCategorias';
import {
	asyncBuscarCategorias,
	asyncDeleteCategoria,
	asyncInativarCategoria,
	asyncAtivarCategoria,
	buscarCategoria,
	alterarNivelCategoria,
	buscarCategoriasTitulosComVinculos,
} from './Requests';

import './Styles/tab-view.css';

function Categorias({ isMobile }) {
	const [categorias, setCategorias] = useState([]);
	const [exibirModalCategoria, setExibirModalCategoria] = useState(false);
	const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);

	const podeVisualizar = usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.VISUALIZAR);
	const podeEditar = usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.EDITAR);
	const podeInserir = usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.INSERIR);
	const podeExcluir = usuarioPossuiPermissao(recursos.CADASTROS_CATEGORIAS_FINANCEIRAS, permissoes.EXCLUIR);
	const informacoesPermissoes = {
		podeInserir: podeInserir,
		podeVisualizar: podeVisualizar,
		podeEditar: podeEditar,
		podeExcluir: podeExcluir,
	};

	useEffect(() => {
		buscarCategorias();
	}, []);

	function pesquisar() {
		buscarCategorias();
	}

	function buscarCategorias() {
		asyncBuscarCategorias(`${services.GESTOR}/v1/categorias`, ({ data: categorias }) => {
			setCategorias(categorias);
		});
	}

	function excluirCategoria(categoria) {
		confirm('Confirmação', `Tem certeza que deseja excluir a categoria?`, () => {
			asyncDeleteCategoria(categoria.id, () => {
				setCategoriaSelecionada(null);
				pesquisar();
			});
		});
	}

	function inativarCategoria(categoria) {
		buscarCategoria(categoria.id, ({ data: categoria }) => {
			confirm('Confirmação', `Tem certeza que deseja inativar a categoria?`, () => {
				asyncInativarCategoria(categoria, () => {
					setCategoriaSelecionada(null);
					pesquisar();
				});
			});
		});
	}

	function ativarCategoria(categoria) {
		buscarCategoria(categoria.id, ({ data: categoria }) => {
			confirm('Confirmação', `Tem certeza que deseja ativar a categoria?`, () => {
				asyncAtivarCategoria(categoria, () => {
					setCategoriaSelecionada(null);
					pesquisar();
				});
			});
		});
	}

	function selecionarCategoria(categoria) {
		setCategoriaSelecionada(categoria);
	}

	function onHideModalCategoria() {
		setExibirModalCategoria(false);
		setCategoriaSelecionada(null);
		pesquisar();
	}

	function alterarNivel(tipoAlterarNivel, categoriaAlterada) {
		alterarNivelCategoria(categoriaAlterada.id, tipoAlterarNivel, () => {
			buscarCategorias();
		});
	}

	function buscarInconsistencias() {
		buscarCategoriasTitulosComVinculos(({ data }) => {
			if (data?.length > 0) {
				const promises = [atualizarCategoriasComInconsistencias(categorias, data)];

				Promise.all(promises).then((data) => {
					if (data?.length > 0) {
						const categorias = data[0];
						reordenarCategoriasComInconsistencias(categorias);
						setCategorias(categorias);
					}
				});
			}
		});
	}

	function atualizarCategoriasComInconsistencias(categorias, inconsistenciasOriginais) {
		const resultado = [];

		if (categorias?.length > 0) {
			let inconsistencias = copiarObjeto(inconsistenciasOriginais);

			categorias.forEach((categoria) => {
				const inconsistenciasEncontradasParaCategoria = inconsistencias?.find(
					(inconsistencia) => inconsistencia.categoriaId === categoria.id
				);

				let newCategoria = copiarObjeto(categoria);

				if (inconsistenciasEncontradasParaCategoria) {
					inconsistencias = inconsistencias.filter(
						(inconsistencia) => inconsistencia.categoriaId !== inconsistenciasEncontradasParaCategoria.categoriaId
					);

					newCategoria = {
						...newCategoria,
						contemInconsistencias:
							inconsistenciasEncontradasParaCategoria.vinculadaFormaPagamento ||
							inconsistenciasEncontradasParaCategoria.vinculadaCondicaoPagamento ||
							inconsistenciasEncontradasParaCategoria.vinculadaParametrosFilial ||
							inconsistenciasEncontradasParaCategoria.vinculadaEmLancamentos,
						vinculadaFormaPagamento: inconsistenciasEncontradasParaCategoria.vinculadaFormaPagamento,
						vinculadaCondicaoPagamento: inconsistenciasEncontradasParaCategoria.vinculadaCondicaoPagamento,
						vinculadaParametrosFilial: inconsistenciasEncontradasParaCategoria.vinculadaParametrosFilial,
						vinculadaEmLancamentos: inconsistenciasEncontradasParaCategoria.vinculadaEmLancamentos,
					};
				}

				if (newCategoria?.subCategorias?.length > 0) {
					newCategoria.subCategorias = atualizarCategoriasComInconsistencias(
						newCategoria.subCategorias,
						inconsistencias
					);
				}

				resultado.push(newCategoria);
			});
		}

		return resultado;
	}

	function reordenarCategoriasComInconsistencias(categorias) {
		categorias.sort((primeiraCategoria, segundaCategoria) => primeiraCategoria.nivel - segundaCategoria.nivel);

		categorias.forEach((categoria) => {
			if (categoria.subCategorias?.length > 0) {
				reordenarCategoriasComInconsistencias(categoria.subCategorias);
			}
		});
	}

	return (
		<>
			<Form header="Categorias">
				<FormActions>
					<ButtonNovo
						label="Nova categoria"
						title="Inserir uma nova categoria"
						onClick={() => setExibirModalCategoria(true)}
						disabled={!podeInserir}
					/>
					<Button
						className="p-button-danger"
						icon="fa fa-exclamation-triangle"
						label="Buscar inconsistências"
						title="Busca inconsistências nas categorias (Exemplo: conta pai com movimentações vinculadas)"
						onClick={buscarInconsistencias}
					/>
				</FormActions>
				<FormContent>
					<TreeTabelaCategorias
						className=".p-tabview-title-categorias-financeiro"
						isMobile={isMobile}
						pesquisar={() => pesquisar()}
						categoriaSelecionada={categoriaSelecionada}
						excluirCategoria={(e) => excluirCategoria(e)}
						inativarCategoria={(e) => inativarCategoria(e)}
						ativarCategoria={(e) => ativarCategoria(e)}
						selecionarCategoria={(e) => selecionarCategoria(e)}
						informacoesPermissoes={informacoesPermissoes}
						exibirModalCategoria={() => setExibirModalCategoria(true)}
						alterarNivel={alterarNivel}
						categorias={categorias}
						tabName="categoriaReceita"
					/>
				</FormContent>
			</Form>
			<If test={exibirModalCategoria}>
				<ModalCategoria
					visible={exibirModalCategoria}
					onHide={() => onHideModalCategoria()}
					categoriaSelecionada={categoriaSelecionada}
					informacoesPermissoes={informacoesPermissoes}
				/>
			</If>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(Categorias);
