import { useEffect, useState } from 'react';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import If from '../../If';
import { ModalVendedores } from 'views/cadastros/vendas/Vendedores/Modal';

export const tipoVendedorfiltro = {
	VENDEDOR: 'VENDEDOR',
	SUPERVISOR: 'SUPERVISOR',
	GERENTE: 'GERENTE',
};

export const tipoBuscaVendedorFiltro = {
	SUPERIOR: 'SUPERIOR',
	DESCENDENTE: 'DESCENDENTE',
};

function SingleSelectVendedor(props) {
	const { url, tipoVendedor, tipoBusca, disabledButton, esconderBotao } = props;

	const [visible, setVisible] = useState(false);
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_VENDEDORES, permissoes.INSERIR));
	const [podeVisualizar] = useState(usuarioPossuiPermissao(recursos.CADASTROS_VENDEDORES, permissoes.VISUALIZAR));
	const [resetPesquisa, setResetPesquisa] = useState(false);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	function buscarTipoFiltroBusca() {
		if (tipoVendedor === tipoVendedorfiltro.VENDEDOR && tipoBusca === tipoBuscaVendedorFiltro.SUPERIOR) {
			return tipoVendedorfiltro.SUPERVISOR;
		} else if (tipoVendedor === tipoVendedorfiltro.SUPERVISOR) {
			if (tipoBusca === tipoBuscaVendedorFiltro.SUPERIOR) {
				return tipoVendedorfiltro.GERENTE;
			} else {
				return tipoVendedorfiltro.VENDEDOR;
			}
		} else if (tipoVendedor === tipoVendedorfiltro.GERENTE && tipoBusca === tipoBuscaVendedorFiltro.DESCENDENTE) {
			return tipoVendedorfiltro.SUPERVISOR;
		}

		return tipoVendedorfiltro.VENDEDOR;
	}

	function buscarUrlPesquisa(pesquisa, page) {
		const tipoFiltroBusca = buscarTipoFiltroBusca();

		switch (tipoFiltroBusca) {
			case tipoVendedorfiltro.SUPERVISOR: {
				return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO";tipo=="SUPERVISOR"&page=${page}&size=50&sort=nome,asc`;
			}
			case tipoVendedorfiltro.GERENTE: {
				return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO";tipo=="GERENTE"&page=${page}&size=50&sort=nome,asc`;
			}
			case tipoVendedorfiltro.VENDEDOR: {
				return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO";tipo=="VENDEDOR"&page=${page}&size=50&sort=nome,asc`;
			}
			default: {
				return `${url}?query=nome=contains="*${pesquisa}*";situacao=="ATIVO";tipo=="VENDEDOR"&page=${page}&size=50&sort=nome,asc`;
			}
		}
	}

	function buscarUrlRegistroUnico(id) {
		return `${url}?query=id==${id}`;
	}

	function montarLabel(row) {
		return `${row.codigo} - ${row.nome}`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			props.onChange({
				label: `${novoRegistro.codigo ?? ''} ${novoRegistro.nome}`,
				value: novoRegistro.id,
				registro: novoRegistro,
			});
			setResetPesquisa(true);
		}
	}

	function esconderModal() {
		setVisible(false);
	}

	return (
		<>
			<If test={visible}>
				<ModalVendedores tipoVendedor={tipoVendedor} visible={visible} insertJustOne={true} onHide={onHide} />
			</If>
			<SingleSelect
				{...props}
				buscarUrlPesquisa={buscarUrlPesquisa}
				buscarUrlRegistroUnico={buscarUrlRegistroUnico}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				esconderBotao={esconderBotao}
				disabledButton={!podeInserirEVisualizar || disabledButton}
				titleBotaoNovo={
					podeInserirEVisualizar && !disabledButton
						? 'Adicionar novo vendedor'
						: 'Você não possui permissão para executar esta ação'
				}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

SingleSelectVendedor.defaultProps = {
	label: 'Vendedor',
	disabledButton: false,
};

SingleSelectVendedor.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
};

export default SingleSelectVendedor;
