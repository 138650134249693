import { useRef } from 'react';
import { useFormikContext } from 'formik';
import { Menu } from 'primereact/menu';
import { FaDownload, FaPrint, FaShareAlt, FaSyncAlt } from 'react-icons/fa';

import { baixarArquivo, colors } from 'Common';
import { If } from 'components';

import { asyncConsultarNfse, imprimirDANFSE, imprimirXMLNfse } from '../../../Requests';
import { useContextNFSe } from '../../../Context';

const styleBotaoImprimir = {
	padding: '0px 10px',
	color: colors.cinzaDark,
	fontWeight: 'bold',
	fontSize: '15px',
	display: 'flex',
	alignItems: 'center',
	border: 'none',
};

export default function HeaderTitulo({ isMobile, asyncSelectRegistro }) {
	const { values } = useFormikContext();
	const { setModalEnviarPorEmailVisible, setModalEnviarPorWhatsappVisible, setExibirLoadingTransmissao, setMessageLoadingTransmissao } = useContextNFSe();
	const menuCompartilhar = useRef(null);
	const title = values.id ? (
		<span>Nota fiscal de serviço (NFS-e)</span>
	) : (
		<span>Nova nota fiscal de serviço (NFS-e)</span>
	);

	async function consultarNotaFiscal(){
		setMessageLoadingTransmissao("Consultando NFS-e...");
		setExibirLoadingTransmissao(true);
		await asyncConsultarNfse(values.id, 
			() => {		
				asyncSelectRegistro(values.id);				
				setExibirLoadingTransmissao(false);
				
			},
			()=>{
				asyncSelectRegistro(values.id);
				setExibirLoadingTransmissao(false);
			}	
		);		
	}

	async function baixarXMLNotaFiscal() {
		await imprimirXMLNfse(values.id, ({ data: file }) => {
			baixarArquivo(file, 'Nota fiscal de serviço (NFS-e)', 'application/xml');
		});
	}	

	async function imprimirNotaFiscal() {
		setMessageLoadingTransmissao("Carregando NFS-e para impressão...");
		setExibirLoadingTransmissao(true);
		await imprimirDANFSE(values.id, 
			async ({ data: file }) => {
				setExibirLoadingTransmissao(false);			
				const arquivo = new Blob([file], { type: 'application/pdf' });
				const arquivoURL = URL.createObjectURL(arquivo);
				const danfe = window.open(arquivoURL);
				danfe.onload = () => {
					setTimeout(() => {
						danfe.document.title = `NFS-e Nº ${values.numero}.pdf`;
					}, 250);
				};
			},
			()=>{			
				setExibirLoadingTransmissao(false);
			}
		);
	}

	const menuCompartilharModel = [
		{
			label: 'Enviar por e-mail',
			icon: 'fa fa-send',
			command: () => setModalEnviarPorEmailVisible(true),
		},
		{
			label: 'Enviar por Whatsapp',
			icon: 'fa fa-whatsapp',
			command: () => setModalEnviarPorWhatsappVisible(true),
		},
	];

	return (
		<span
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				flexDirection: isMobile ? 'column' : 'row',
			}}
		>
			{title}
			<span
				style={{
					display: 'flex',
					alignItems: 'center',
					fontSize: '16px',
					cursor: 'pointer',
					paddingTop: isMobile ? '8px' : 0,
				}}
			>
				<If test={values.situacao === 'AGUARDANDO'}>

					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>						
						<span
							title="Atualizar informações da nota"
							style={{ cursor: 'pointer' }}
							onClick={() => consultarNotaFiscal()}
						>
							<span style={{ ...styleBotaoImprimir }}>
								<FaSyncAlt style={!isMobile && { marginRight: '5px' }} />
								{!isMobile && <span>Consultar NFS-e</span>}
							</span>
						</span>
					</div>
				</If>
				<If test={values.situacao === 'TRANSMITIDO'}>
					<span
						style={{ ...styleBotaoImprimir, cursor: 'pointer' }}
						onClick={(event) => menuCompartilhar.current.toggle(event)}
					>
						<Menu
							style={{ width: '220px' }}
							model={menuCompartilharModel}
							popup
							ref={menuCompartilhar}
							id="menu_compartilhar"
						/>
						<FaShareAlt size="15px" style={!isMobile && { marginRight: '5px' }} />
						{!isMobile && <span>Compartilhar</span>}
					</span>
					<span style={{ ...styleBotaoImprimir }} target="_blank" onClick={baixarXMLNotaFiscal}>
						<FaDownload size="15px" style={{ marginRight: '5px' }} />
						<span>Baixar XML</span>
					</span>

					<span style={{ ...styleBotaoImprimir }} target="_blank" onClick={imprimirNotaFiscal}>
						<FaPrint size="15px" style={{ marginRight: '5px' }} />
						<span>Imprimir DANFS-e</span>
					</span>
				</If>
			</span>
		</span>
	);
}
