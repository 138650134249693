import { services } from 'Common';
import SingleSelect from '../SingleSelect';

export default function SingleSelectEtiqueta({ label = 'Etiqueta', onChange, ...props }) {
	function buscarUrlPesquisa(pesquisa, page) {
		return `${services.GESTOR}/v1/etiquetas/resumo?query=codigo=contains="*${pesquisa}*",descricao=contains="*${pesquisa}*"&page=${page}&size=50&sort=codigo`;
	}

	function montarLabel(row) {
		return `${row.codigo} - ${row.descricao}`;
	}

	return (
		<SingleSelect
			{...props}
			buscarUrlPesquisa={buscarUrlPesquisa}
			montarLabel={montarLabel}
			label={label}
			onChange={onChange}
			esconderBotao
		/>
	);
}
