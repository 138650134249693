import { useState } from 'react';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import ModalAgenteAutorizado from '../../../views/administracao/AgentesAutorizados/Modal';

export default function SingleSelectAgenteAutorizado(props) {
	const { url, service, esconderBotao } = props;

	const [visible, setVisible] = useState(false);
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR)
	);
	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.INSERIR));

	function buscarUrlPesquisa(pesquisa, page) {
		return `${url}?query=(nome=contains="*${pesquisa}*",cpf=contains="*${pesquisa}*",cnpj=contains="*${pesquisa}*");situacao==ATIVO&page=${page}&size=50&sort=nome`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro) {
			props.onChange({
				label: montarLabel(novoRegistro),
				value: novoRegistro.id,
			});
		}
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		if (row.cpf) {
			return row.nome + ' - ' + row.cpf;
		} else {
			return row.nome + ' - ' + row.cnpj;
		}
	}

	return (
		<>
			<ModalAgenteAutorizado service={service} visible={visible} onHide={onHide} />
			<SingleSelect
				{...props}
				titleBotaoNovo="Adicionar novo agente autorizado"
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				disabledButton={!podeVisualizar || !podeInserir}
				esconderBotao={esconderBotao}
				onChange={props.onChange}
			/>
		</>
	);
}

SingleSelectAgenteAutorizado.defaultProps = {
	label: 'Agente autorizado',
};

SingleSelectAgenteAutorizado.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
	/** Filtor adicional a ser passado para o RSQL ex: ";tipo=="PRODUTO";controlarEstoque==true"*/
	filtroAdicionalUrl: propTypes.string,
};
