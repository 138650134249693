let keys = {};

export function converterParaTreeTable(categoriasCompleta) {
	let childrens = [];

	categoriasCompleta.forEach((categoria, key) => {
		childrens.push(
			recursiveFunction(
				key,
				categoria,
				categoria.nivel,
				categoria?.subCategorias?.length || 0,
				key === 0,
				key === categoriasCompleta?.length - 1
			)
		);
	});

	return {
		root: childrens,
		chaves: keys,
	};
}

function recursiveFunction(key, categoria, nivelSuperior, totalRegistrosMesmoNivel, isFirst, isLast) {
	let childrens = [];

	if (categoria.subCategorias.length > 0) {
		keys = { ...keys, [key]: true };

		categoria.subCategorias.forEach((subCategoria, key2) => {
			let chave = `${key}-${key2}`;
			let nivel = `${nivelSuperior}.${subCategoria.nivel}`;
			keys = { ...keys, [chave]: true };

			const isPrimeiroRegistro = key2 === 0;
			const isUltimoRegistro = key2 === totalRegistrosMesmoNivel - 1;

			childrens.push(
				recursiveFunction(
					chave,
					subCategoria,
					nivel,
					subCategoria.subCategorias.length || 0,
					isPrimeiroRegistro,
					isUltimoRegistro
				)
			);
		});
	}

	return {
		key: `${key}`,
		nivel: `${nivelSuperior}`,
		primeiroRegistro: isFirst || false,
		ultimoRegistro: isLast || false,
		data: categoria,
		children: childrens,
	};
}
