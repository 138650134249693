import { connect } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { AutoProgressBar, Checkbox, Form, FormActions, FormContent, Grid, If } from 'components';

import { ModalDetalhesPix } from 'components/ModalDetalhesPix';
import { ModalEdicaoContasReceber } from 'views/financas/ContasReceber/components/ModalEdicaoContasReceber';
import { ModalExclusaoContasReceber } from 'views/financas/ContasReceber/components/ModalExclusaoContasReceber';
import { ActionButtons } from './components/ActionButtons';
import { FormBodyContaReceber } from './components/FormBodyContaReceber';
import { FormCompensacaoAutomaticaETaxa } from './components/FormCompensacaoAutomaticaETaxa';
import { FormInformacoesComplementares } from './components/FormInformacoesComplementares';
import { FormMultiplasParcelas } from './components/FormMultiplasParcelas';
import { FormNovoRecebimento } from './components/FormNovoRecebimento';
import { FormTipoContaReceber } from './components/FormTipoContaReceber';
import { FormValorRecebido } from './components/FormValorRecebido';
import { TIPO_CONTA_RECEBER } from './Util/constantes';

function FormReceber({
	isMobile,
	isModal,
	onHideModal,
	history,
	onNovoClick,
	onHideModalExclusao,
	onHideModalEdicao,
	atualizarReceber,
	atribuirValoresIniciais,
	buscarValor,
	podeEditar,
	setRegistroSelecionado,
	exibirModalExclusao,
	setExibirModalExclusao,
	exibirModalConfirmacaoEdicao,
	setExibirModalConfirmacaoEdicao,
	modalDetalhesPixVisible,
	setModalDetalhesPixVisible,
	activeIndex,
	setActiveIndex,
	filialConectada,
	informacoesPermissoes,
	isEdicao,
	isReparcelamento = false,
	isReparcelada = false,
	disabledFieldReparcelamento,
}) {
	const { values, setFieldValue } = useFormikContext();

	return (
		<div>
			<AutoProgressBar />
			<Form>
				<If test={!isReparcelamento}>
					<FormActions>
						<ActionButtons
							onHide={onHideModal}
							filialConectadaId={filialConectada.id}
							{...{
								informacoesPermissoes,
								isModal,
								isMobile,
								setExibirModalExclusao,
								onNovoClick,
								history,
								setRegistroSelecionado,
								atribuirValoresIniciais,
								setExibirModalConfirmacaoEdicao,
								isReparcelada,
							}}
						/>
					</FormActions>
				</If>
				<FormContent>
					<Grid>
						<FormBodyContaReceber
							{...{ informacoesPermissoes, isReparcelamento, isReparcelada, disabledFieldReparcelamento }}
						/>
						<If test={!isEdicao}>
							<FormTipoContaReceber {...{ isReparcelamento }} />

							<If test={values.tipo !== TIPO_CONTA_RECEBER.UNICA}>
								<FormMultiplasParcelas {...{ informacoesPermissoes, buscarValor }} />
							</If>
						</If>

						<If test={!isReparcelamento}>
							<Field
								sm="12"
								md="12"
								lg="12"
								xl="12"
								colStyle={{ marginTop: '10px' }}
								component={Checkbox}
								label={
									values.tipo === TIPO_CONTA_RECEBER.VALOR_PARCELADO
										? 'Valor primeira parcela já recebido'
										: 'Valor já recebido'
								}
								name="recebido"
								hidden={isEdicao}
								onChange={() => {
									setFieldValue('recebido', !values.recebido);
									if (!values.recebido) {
										setFieldValue('valorRecebido', buscarValor());
									}
								}}
								checked={values.recebido}
								{...informacoesPermissoes}
							/>
							<If test={values.recebido}>
								<FormValorRecebido {...{ informacoesPermissoes }} />
							</If>
						</If>

						<If test={isEdicao}>
							<FormNovoRecebimento
								{...{
									informacoesPermissoes,
									podeEditar,
									setModalDetalhesPixVisible,
									isReparcelada,
								}}
							/>
						</If>
						<Accordion
							className="tab-accordion-lite"
							style={{ marginTop: '10px' }}
							activeIndex={activeIndex}
							onTabChange={(e) => setActiveIndex(e.index)}
							multiple
						>
							<AccordionTab header="Compensação e taxas">
								<FormCompensacaoAutomaticaETaxa {...{ informacoesPermissoes, isReparcelada }} />
							</AccordionTab>

							<AccordionTab header="Informações complementares">
								<FormInformacoesComplementares {...{ informacoesPermissoes, isReparcelada }} />
							</AccordionTab>
						</Accordion>
					</Grid>
				</FormContent>
			</Form>
			<If test={exibirModalExclusao}>
				<ModalExclusaoContasReceber visible={exibirModalExclusao} onHide={onHideModalExclusao} />
			</If>
			<If test={exibirModalConfirmacaoEdicao}>
				<ModalEdicaoContasReceber visible={exibirModalConfirmacaoEdicao} onHide={onHideModalEdicao} />
			</If>
			<If test={modalDetalhesPixVisible}>
				<ModalDetalhesPix
					visible={modalDetalhesPixVisible}
					pixId={values.pix?.id}
					valor={values.pix?.valor}
					onHide={() => {
						setModalDetalhesPixVisible(false);
						atualizarReceber();
					}}
				/>
			</If>
		</div>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export default connect(mapStateToProps)(FormReceber);
