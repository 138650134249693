import SingleSelect from '../SingleSelect';
import propTypes from 'prop-types';

function SingleSelectVendedorListagem(props) {
	const { url } = props;

	function buscarUrlPesquisa(pesquisa, page) {
		return `${url}?filtro=${pesquisa ?? ''}&page=${page}&size=500&sort=codigo,asc`;
	}

	function montarLabel(row) {
		return `${row.codigo} - ${row.nome}`;
	}

	return <SingleSelect {...props} esconderBotao montarLabel={montarLabel} buscarUrlPesquisa={buscarUrlPesquisa} />;
}

SingleSelectVendedorListagem.defaultProps = {
	label: 'Vendedor',
	disabledButton: false,
};

SingleSelectVendedorListagem.propTypes = {
	/** Url do servidor em que as requisições de busca deverão ser efetuadas: Ex:  `${services.GESTOR}/v1/usuarios/relacoes/pessoas`*/
	url: propTypes.string.isRequired,
};

export default SingleSelectVendedorListagem;
