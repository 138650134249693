import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
	buscarConfiguracaoUsuario,
	configuracoesUsuario,
	construirUrl,
	permissoes,
	recursos,
	salvarConfiguracaoUsuario,
	services,
	usuarioPossuiPermissao,
} from 'Common';
import {
	Button,
	DescricaoFiltroAvancado,
	Form,
	FormActions,
	FormContent,
	Grid,
	InputSearch,
	PesquisaAvancada,
	ToastTypes,
	Tutorial,
	notify,
	tipoCampos,
	tutorialStepsListagens,
} from 'components';

import { atualizarUrl } from 'views/Util';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';
import { confirmarExclusao } from 'views/Util/ExclusaoDeRegistros';

import { TabelaVendedores } from './Components/TabelaVendedores';
import { deletarVendedor, getUsuarios, getVendedoresResumo } from './Requests';
import { OPTIONS_FILTRO_AVANCADO_VENDEDORES } from './Utils/constantes';

function VendedoresView({ history, isMobile }) {
	const [podeInserir] = useState(usuarioPossuiPermissao(recursos.CADASTROS_VENDEDORES, permissoes.INSERIR));

	const {
		valorPesquisa,
		setValorPesquisa,
		sortField,
		setSortField,
		sortOrder,
		setSortOrder,
		page,
		setPage,
		rows,
		setRows,
		size,
		setSize,
		totalRecords,
		setTotalRecords,
		filtroAvancado,
		setFiltroAvancado,
		descricaoFiltroAvancado,
		setDescricaoFiltroAvancado,
	} = useContextPesquisa();

	const [registros, setRegistros] = useState([]);
	const [registroSelecionado, setRegistroSelecionado] = useState({});
	const [deveExibirTutorial] = useState(buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS));
	const [tutorialVisible, setTutorialVisible] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
	const [filtroAvancadoOptions, setFiltroAvancadoOptions] = useState(OPTIONS_FILTRO_AVANCADO_VENDEDORES);

	const fetchCallback = useCallback(() => {
		if (!firstRender) {
			fetchVendedores();
		}
	});

	useEffect(() => {
		fetchVendedores();

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_LISTAGENS, false, null, false);
		}

		setTimeout(() => {
			document.getElementById('VendedorInputSearch')?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		fetchCallback();
	}, [page, rows, sortOrder, sortField, filtroAvancado]);

	async function fetchVendedores() {
		const filter = getFilter();
		let campoOrdenacao = sortField;
		let sentidoOrdenacao = sortOrder;
		if (firstRender) {
			setSortField('codigo');
			setSortOrder(1);
			campoOrdenacao = 'codigo';
			sentidoOrdenacao = 1;
		}
		const url = construirUrl(
			`${services.GESTOR}/v1/vendas/vendedor/resumo`,
			filter || '?',
			size,
			page,
			sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
		);

		const promises = [
			getVendedoresResumo(url, ({ data: response }) => {
				setRegistros(response.content);
				setTotalRecords(response.totalElements);
				setFirstRender(false);
			}),
		];

		await Promise.all(promises);
	}

	function getFilter() {
		let filter = String('?query=(').concat(`nome=contains="*${valorPesquisa.replaceAll('&', '%26')}*")`);

		if (filtroAvancado) {
			filter = filter.concat(`;(${filtroAvancado})`);
		}

		return filter;
	}

	function handleChangeOrder(event) {
		setSortField(event.sortField);
		setSortOrder(event.sortOrder);
	}

	function handlePageChange(event) {
		setSize(event.rows);
		setRows(event.rows);
		setPage(event.page);
	}

	function handleClickNovo() {
		atualizarUrl(history, '/vendas/vendedores/cadastro');
	}

	function handleClickInputSearch(value) {
		setValorPesquisa(value);
	}

	async function handleSearchFiltroAvancado(filtro) {
		setLimparFiltroPesquisaAvancada(false);
		setFiltroAvancado(filtro);
	}

	async function fetchOptionsFiltroAvancado() {
		const options = [...filtroAvancadoOptions];

		function addOption(newOption) {
			let indexOption = 0;
			const hasOption = options.find((e, i) => {
				if (e.name === newOption.name) {
					indexOption = i;
					return true;
				}
				return false;
			});

			if (hasOption) {
				options.splice(indexOption, 1);
			}

			options.push(newOption);
		}

		const promises = [
			getUsuarios(({ data: response }) => {
				if (response.content) {
					const newOption = {
						label: 'Usuário',
						name: 'usuarioId',
						type: tipoCampos.SELECT,
						optionSelect: [],
					};
					response.content.forEach((option) => {
						newOption.optionSelect.push({ label: option.nome, value: option.id });
					});
					addOption(newOption);
				}
			}),
		];

		await Promise.all(promises).then(() => {
			setFiltroAvancadoOptions(
				options.sort((a, b) => {
					if (a.label < b.label) {
						return -1;
					}
					if (a.label > b.label) {
						return 1;
					}
					return 0;
				})
			);
		});
	}

	function handleChangeFiltroRsql(rsql) {
		setFiltroAvancado(rsql);
	}

	function handleChangeDescricaoFiltro(value) {
		setDescricaoFiltroAvancado(value);
	}

	function handleClickDeletar(row) {
		confirmarExclusao(() => excluirRegistro(row));
	}

	async function excluirRegistro(registro) {
		await deletarVendedor(
			registro.id,
			() => {
				fetchCallback();
			},
			() => {
				notify('Falha ao remover o registro', ToastTypes.ERROR);
			}
		);
	}

	return (
		<>
			<Tutorial
				steps={tutorialStepsListagens}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
				disableScrolling
			/>
			<Form header="Vendedores">
				<FormActions>
					<Button
						className="step-listagem-novo p-button-primary"
						label="Novo vendedor"
						icon="fa fa-plus"
						title="Inserir um novo vendedor"
						onClick={handleClickNovo}
						podeInserir={podeInserir}
						disabled={!podeInserir}
					/>
				</FormActions>
				<FormContent>
					<Grid justifyCenter verticalAlignCenter>
						<InputSearch
							id="VendedorInputSearch"
							className="step-listagem-input-search"
							value={valorPesquisa}
							onChange={handleClickInputSearch}
							onPesquisar={fetchCallback}
						/>
						<span className="step-listagem-filtro-avancado" style={{ padding: '12px' }}>
							<PesquisaAvancada
								optionsFiltros={filtroAvancadoOptions}
								onPesquisarClick={handleSearchFiltroAvancado}
								onChangeFiltroRsql={handleChangeFiltroRsql}
								onChangeDescricaoFiltro={handleChangeDescricaoFiltro}
								limparFiltro={limparFiltroPesquisaAvancada}
								onOpen={fetchOptionsFiltroAvancado}
							/>
						</span>
					</Grid>
					<Grid justifyBetween>
						<DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
						<TabelaVendedores
							isMobile={isMobile}
							history={history}
							registros={registros}
							rows={size}
							page={page}
							sortField={sortField}
							sortOrder={sortOrder}
							totalRecords={totalRecords}
							handlePageChange={handlePageChange}
							handleChangeOrder={handleChangeOrder}
							handleClickDeletar={handleClickDeletar}
							registroSelecionado={registroSelecionado}
							setRegistroSelecionado={setRegistroSelecionado}
						/>
					</Grid>
				</FormContent>
			</Form>
		</>
	);
}

const mapStateToProps = (state) => ({
	isMobile: state.dispositivo.isMobile,
});

export const Vendedores = connect(mapStateToProps)(VendedoresView);
