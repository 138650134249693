import SingleSelect from '../SingleSelect';
import { services } from '../../../Common/Constantes/api';

function SingleSelectTipoCampoEtiqueta(props) {
	function buscarUrlPesquisa(pesquisa, page) {
		return `${services.GESTOR}/v1/etiquetas/campos_disponiveis?query=descricao=contains="*${pesquisa}*",nome=contains="*${pesquisa}*"&page=${page}&size=50`;
	}

	function montarLabel(row) {
		return row.descricao;
	}

	return (
		<>
			<SingleSelect
				{...props}
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				label={props.label}
				onChange={props.onChange}
				esconderBotao
			/>
		</>
	);
}

SingleSelectTipoCampoEtiqueta.defaultProps = {
	label: 'Tipo campo',
};

export default SingleSelectTipoCampoEtiqueta;
