import { useState } from 'react';
import { recursos, permissoes } from '../../../Common/Constantes/autorizacao';
import SingleSelect from '../SingleSelect';
import { usuarioPossuiPermissao } from '../../../Common/Autenticacao';
import { services } from '../../../Common/Constantes/api';

export default function SingleSelectCest(props) {
	const [podeVisualizar, setPodeVisualizar] = useState(
		usuarioPossuiPermissao(recursos.PRODUTOS, permissoes.VISUALIZAR)
	);

	function buscarUrlPesquisa(pesquisa, page) {
		return `${services.GESTOR}/v1/cests?query=codigo=contains="*${pesquisa}*",descricao=contains="*${pesquisa}*"&page=${page}&size=50&sort=codigo`;
	}

	function montarLabel(row) {
		return row.codigo + ' - ' + row.descricao;
	}

	return (
		<>
			<SingleSelect
				{...props}
				esconderBotao
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				disabledButton={!podeVisualizar}
				onChange={props.onChange}
			/>
		</>
	);
}

SingleSelectCest.defaultProps = {
	label: 'CEST',
};
