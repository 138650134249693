import { useState } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { MdClose } from 'react-icons/md';

import { Button, Grid, If, SingleSelectCategoria, tiposCategoria } from 'components';
import { colors, services } from 'Common';

import { atualizarRegistrosComInconsistencias } from 'views/cadastros/financas/Categorias/Requests';

function TreeTabelaCategoriaOverlayCategoriaComInconsistencia({ registroSelecionado, pesquisar }) {
	const [novaCategoria, setNovaCategoria] = useState(null);

	function renderOpcoesVinculadas(vinculado, label) {
		return (
			<div style={{ display: 'flex', justifyItems: 'center', gap: '8px', marginLeft: '8px' }}>
				<If test={!vinculado}>
					<MdClose size="24px" style={{ color: colors.vermelho }} />
				</If>
				<If test={vinculado}>
					<IoMdCheckmark size="24px" style={{ color: colors.verde }} />
				</If>
				{label}
			</div>
		);
	}

	function getTipoCategoria() {
		if (registroSelecionado.receita && registroSelecionado.despesa) {
			return null;
		} else if (registroSelecionado.receita) {
			return tiposCategoria.RECEITA;
		} else if (registroSelecionado.despesa) {
			return tiposCategoria.DESPESA;
		}
		return null;
	}

	function atualizarCategoriaInconsistente() {
		atualizarRegistrosComInconsistencias(
			{
				idCategoriaInconsistente: registroSelecionado.id,
				idNovaCategoria: novaCategoria.value,
			},
			() => {
				document
					.getElementById('overlayPanel-categoria-inconsistencia')
					?.getElementsByClassName('p-overlaypanel-close p-link')[0]
					?.click();

				if (typeof pesquisar === 'function') {
					pesquisar();
				}
			}
		);
	}

	return (
		<Grid style={{ display: 'flex', flexDirection: 'column', padding: '8px' }}>
			<div style={{ fontWeight: 'bold', marginBottom: '8px' }}>Categoria vinculada em:</div>

			{renderOpcoesVinculadas(registroSelecionado?.vinculadaCondicaoPagamento, 'Condições de pagamento')}
			{renderOpcoesVinculadas(registroSelecionado?.vinculadaFormaPagamento, 'Formas de pagamentos')}
			{renderOpcoesVinculadas(registroSelecionado?.vinculadaParametrosFilial, 'Parametrizações da filial')}
			{renderOpcoesVinculadas(registroSelecionado?.vinculadaEmLancamentos, 'Utilizada em lançamentos')}

			<If
				test={
					registroSelecionado?.vinculadaCondicaoPagamento ||
					registroSelecionado?.vinculadaFormaPagamento ||
					registroSelecionado?.vinculadaParametrosFilial ||
					registroSelecionado?.vinculadaEmLancamentos
				}
			>
				<div style={{ fontWeight: 'bold', marginTop: '8px' }}>Alterar categoria inconsistente dos registros para:</div>
				<SingleSelectCategoria
					colStyle={{ padding: '0.5rem 0rem' }}
					name="novaCategoria"
					label=""
					tipoCategoria={getTipoCategoria()}
					value={novaCategoria}
					onChange={(e) => setNovaCategoria(e)}
					esconderBotao
					url={`${services.GESTOR}/v1/categorias/apenas_filhos`}
				/>
				<Button
					style={{ width: '100%' }}
					label="Alterar categoria"
					color="success"
					disabled={!novaCategoria}
					onClick={atualizarCategoriaInconsistente}
				/>
			</If>
		</Grid>
	);
}

export { TreeTabelaCategoriaOverlayCategoriaComInconsistencia };
