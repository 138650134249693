import { actionTypes, buscarDadosLoginLocalStorage, dispatchAction } from 'Common';
import { fazerLogin, salvarDadosLoginLocalStorage } from 'Common/Autenticacao';
import { Divider, If, ToastTypes, notify } from 'components';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { atualizarUrl } from 'views/Util';
import { asyncGetFiliaisDoUsuario } from 'views/configuracao/Usuarios/Form/Requests';
import { asyncAlterarFilialConectada, asyncAlterarOrganizacao, asyncGetOrganizacaoFiliaisSetores } from './Request';
import { converterFiliaisSetoresToForm, converterFilialToSelect, converterOrganizacaoToSelect } from './Util/converter';
import { ModalFilialSetor } from './components/ModalFilialSetor';
import { SelectFiliais } from './components/SelectFiliais';
import { SelectOrganizacao } from './components/SelectOrganizacao';

function SelectOrganizacaoFilial({ history, atualizacaoFilialMenuLateral }) {
	const [resetPesquisa, setResetPesquisa] = useState(false);
	const [visibleModalFilialSetor, setVisibleModalFilialSetor] = useState(false);
	const [optionsFiliaisSetores, setOptionsFiliaisSetores] = useState({});
	const [data, setData] = useState({
		organizacao: {},
		filial: {},
	});
	const initialValues = useRef();
	const localStorage = buscarDadosLoginLocalStorage();

	const showSelectOrganizacao = ['HELPDESK', 'ACCOUNTANT'].includes(localStorage.autoridade);

	useEffect(() => {
		if (localStorage?.organizacao?.id) {
			updateOrganizacaoConectada(localStorage?.organizacao);
			initialValues.current = {
				...initialValues.current,
				organizacao: converterOrganizacaoToSelect(localStorage?.organizacao),
			};
		}
		if (localStorage?.filialConectada?.id) {
			updateFilialConectada(localStorage?.filialConectada);
			initialValues.current = {
				...initialValues.current,
				filial: converterFilialToSelect(localStorage?.filialConectada),
			};
		}
	}, []);

	useEffect(async () => {		
		if (atualizacaoFilialMenuLateral) {
			setResetPesquisa(true);
			await asyncGetFiliaisDoUsuario(
				async ({ data: body }) => {
					const filiais = body.content;
					let filialPercenteAoUsuario = false;

					filiais?.forEach((filial) => {
						if (filial.id === data.filial.value) {
							data.filial.label = `${filial.codigo} - ${filial.nomeApresentacao ?? filial.nome}`;
						}

						if (filial.id === localStorage.filialConectada.id) {
							filialPercenteAoUsuario = true;
							updateOrganizacaoConectada(localStorage?.organizacao);
							updateFilialConectada(filial);
						}
					});

					if (filialPercenteAoUsuario) {
						dispatchAction(actionTypes.MENU_LATERAL_ATUALIZAR_FILIAL, false);
					} else {
						await alteraFilialConectadaAndRefazLogin(filiais[0].id);
					}
				},
				() => {
					dispatchAction(actionTypes.MENU_LATERAL_ATUALIZAR_FILIAL, false);
				}
			);
		} 
	}, [atualizacaoFilialMenuLateral]);

	async function updateOrganizacaoConectada(organizacao) {
		setData((state) => ({
			...state,
			organizacao: converterOrganizacaoToSelect(organizacao),
		}));
	}

	async function updateFilialConectada(filial) {
		setData((state) => ({
			...state,
			filial: converterFilialToSelect(filial),
		}));
	}

	async function handleChangeOrganizacao(organizacao) {
		if (organizacao.value === data.organizacao?.value) {
			return;
		}
		
		atualizarUrl(history, '/trocar_organizacao_filial');
		
		if (history?.location.pathname === '/trocar_organizacao_filial'){
			await asyncGetOrganizacaoFiliaisSetores(
				organizacao.value,
				({ data: filiaisSetores }) => {
					
					if (filiaisSetores.filiais.length === 0) {
						notify(`Organização sem filial cadastrada`, ToastTypes.ERROR);
					} else if (filiaisSetores.setores.length === 0) {
						notify(`Organização sem setor cadastrado`, ToastTypes.ERROR);
					} else if (filiaisSetores.filiais.length > 1 || filiaisSetores.setores.length > 1) {
						setOptionsFiliaisSetores(converterFiliaisSetoresToForm(filiaisSetores));
						setVisibleModalFilialSetor(true);

						setData((state) => ({
							...state,
							organizacao,
						}));
					} else {						
						alterarOrganizacaoConectada(filiaisSetores.filiais[0].id, filiaisSetores.setores[0].id, organizacao);

						const newState = {
							organizacao,
							filial: converterFiliaisSetoresToForm(filiaisSetores).filiais[0],
						};
						setData(newState);
						initialValues.current = newState;
						setTimeout(() =>{ 
							if (history?.location.pathname === '/trocar_organizacao_filial'){
								atualizarUrl(history, '/');
							}	
						},1000);
					}
				},
				() => restoreValues()
			);
		}
	}

	async function handleChangeFilial(filial) {
		if (filial.value === data.filial?.value) {
			return;
		}

		atualizarUrl(history, '/trocar_organizacao_filial');

		if (history?.location.pathname === '/trocar_organizacao_filial'){
			
			await asyncAlterarFilialConectada(
				filial.value,
				async({ data: filialConectada }) => {
					await salvarDadosLoginLocalStorage({
						...localStorage,
						filialConectada: filialConectada,
					});
					setData((state) => {
						const newState = {
							...state,
							filial,
						};
						initialValues.current = newState;
						return newState;
					});
					await reFazerLogin();
					setTimeout(() =>{ 
						if (history?.location.pathname === '/trocar_organizacao_filial'){
							atualizarUrl(history, '/');
						}	
					},1000);

				},
				() => {
					notify(`Ocorreu um erro ao alterar a filial`, ToastTypes.ERROR);
				}
			);
		}
	}

	async function reFazerLogin() {
		await fazerLogin(
			localStorage.email,
			localStorage.senha,
			localStorage.manterConectado,
			() => {				
				setResetPesquisa(true);
			},
			() => {
				notify(`Ocorreu um erro ao fazer login`, ToastTypes.ERROR);
			},
			false
		);
	}

	async function alteraFilialConectadaAndRefazLogin(filialId) {
		await asyncAlterarFilialConectada(
			filialId,
			async ({ data: filial }) => {
				salvarDadosLoginLocalStorage({
					...localStorage,
					filialConectada: filial,
				});
				await updateFilialConectada(filial);
				reFazerLogin();

				setTimeout(() => {
					dispatchAction(actionTypes.MENU_LATERAL_ATUALIZAR_FILIAL, false);
				}, 500);
			},
			() => {
				notify(`Ocorreu um erro ao alterar a filial`, ToastTypes.ERROR);
				dispatchAction(actionTypes.MENU_LATERAL_ATUALIZAR_FILIAL, false);
			}
		);
	}

	async function alterarOrganizacaoConectada(filialId, setorFavorito, organizacao = null) {
		const payload = {
			organizacaoId: organizacao ? organizacao.value : data.organizacao?.value,
			filialId,
			setorFavorito,
		};

		await asyncAlterarOrganizacao(
			payload,
			() => {
				initialValues.current = {
					...initialValues.current,
					organizacao: organizacao ?? data.organizacao,
				};
				reFazerLogin();
			},
			() => {
				notify(`Ocorreu um erro ao alterar a organização`, ToastTypes.ERROR);
				restoreValues();
			}
		);
	}

	function onHideModalFilialSetor(filial) {
		setVisibleModalFilialSetor(false);

		if (filial) {
			setData((state) => ({
				...state,
				filial: filial,
			}));
		}
		setTimeout(() =>{ 
			if (history?.location.pathname === '/trocar_organizacao_filial'){
				atualizarUrl(history, '/');
			}	
		},1000);	
	}

	function onCancelModalFilialSetor() {
		setVisibleModalFilialSetor(false);
		restoreValues();
		setTimeout(() =>{ 
			if (history?.location.pathname === '/trocar_organizacao_filial'){
				atualizarUrl(history, '/');
			}	
		},1000);	
	}

	function restoreValues() {
		setData(initialValues.current);
	}

	return (
		<>
			<If test={showSelectOrganizacao}>
				<Divider
					styleLine={{ opacity: '1', color: 'rgb(177, 177, 177)' }}
					styleContainer={{ width: '100%', margin: '0.5rem 0rem 0rem' }}
				/>
				<SelectOrganizacao
					style={{ width: '100%' }}
					value={data.organizacao}
					handleChangeOrganizacao={handleChangeOrganizacao}
				/>
				<If test={visibleModalFilialSetor}>
					<ModalFilialSetor
						visible={visibleModalFilialSetor}
						onHide={onHideModalFilialSetor}
						onConfirm={alterarOrganizacaoConectada}
						onCancel={onCancelModalFilialSetor}
						options={optionsFiliaisSetores}
					/>
				</If>
			</If>
			<SelectFiliais
				style={{ width: '100%' }}
				value={data.filial}
				handleChangeFilial={handleChangeFilial}
				showLabel={showSelectOrganizacao}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

const mapStateToProps = (state) => ({
	atualizacaoFilialMenuLateral: state.atualizacaoFilialMenuLateral,
});

export default withRouter(connect(mapStateToProps)(SelectOrganizacaoFilial));
