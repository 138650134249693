import { useCallback, useEffect, useState } from 'react';
import { format, formatISO } from 'date-fns';
import {
  baixarArquivo,
  construirUrl,
  formatarParaPesquisarTiposEnumerados,
  permissoes,
  recursos,
  services,
  usuarioPossuiPermissao,
} from '../../../../../../../../../Common';
import {
  Button,
  confirm,
  DateInterval,
  DescricaoFiltroAvancado,
  Grid,
  InputSearch,
  Modal,
  ModalLoadingTransmissao,
  PesquisaAvancada,
} from '../../../../../../../../../components';
import { useContextNFCe } from '../../../../../Context';

import { optionsFiltroAvancado, Status } from '../../../../../Util/constantes';
import { asyncGetNfce, asyncGetResumoNfce, asyncGetDownloadXmls } from '../../../../../Requests';
import { atualizarUrl } from '../../../../../../../../Util';
import { converterNfceParaFormulario } from '../../../../../Util/NFCeConverter';

import TabelaNFCe from './TabelaNFCe';
import { useContextPesquisa } from 'views/Util/Context/ContextPesquisa';

function currentMonth() {
  let date = new Date();
  let firstDay = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
  let lastDay = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
  let formatedDate = `emissao>=${firstDay};emissao<=${lastDay}`;

  return formatedDate;
}

function ModalListagem(props) {
  const { onHide, isMobile, isTablet, visible, dirty, resetForm, isFullScreen, isLessHd } = props;

  const { setPodeInserir } = useContextNFCe();

  const {
    valorPesquisa,
    setValorPesquisa,
    interval,
    setInterval,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    rows,
    setRows,
    size,
    setSize,
    totalRecords,
    setTotalRecords,
    filtroAvancado,
    setFiltroAvancado,
    descricaoFiltroAvancado,
    setDescricaoFiltroAvancado,
    filtroData,
    setFiltroData,
  } = useContextPesquisa();

  const [registros, setRegistros] = useState([]);
  const valorTiposEnumerados = formatarParaPesquisarTiposEnumerados(valorPesquisa);
  const [limparFiltroPesquisaAvancada, setLimparFiltroPesquisaAvancada] = useState(false);
  const [optsFiltroAvancado, setOptsFiltroAvancado] = useState(optionsFiltroAvancado);
  const [mostrarLoading, setMostrarLoading] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const pesquisarCallback = useCallback(e => {
    if (!firstRender){      
      pesquisar(); 
    }
  });

  useEffect(() => {
    pesquisar();
    setTimeout(() => {
      document.getElementById('nfce-input-search')?.focus();
    }, 500);
  }, []);

  useEffect(() => {
    pesquisarCallback();
  }, [page, rows, sortOrder, sortField, filtroAvancado, interval]);

  function handleChangeInterval(interval) {
    setInterval(interval);
    setFiltroData(
      `emissao>=${formatISO(interval.dataInicial, {
        representation: 'date',
      })};emissao<=${formatISO(interval.dataFinal, {
        representation: 'date',
      })}`
    );
  }

  function onPesquisar() {
    pesquisar();
  }

  async function pesquisar() {
    if (interval.dataInicial != null && interval.dataFinal != null) {
      const filtro = buscarFiltro();
      let campoOrdenacao = sortField;
      let sentidoOrdenacao = sortOrder;
      if (firstRender){
        setSortField("emissao");
        setSortOrder(-1);
        campoOrdenacao = "emissao";
        sentidoOrdenacao = -1;
      }
      const url = construirUrl(
        `${services.GESTOR}/v1/nfce/resumo`,
        filtro || '?',
        size,
        page,
        sentidoOrdenacao > 0 ? `${campoOrdenacao},asc` : `${campoOrdenacao},desc`
      );

      asyncGetResumoNfce(url, ({ data: nfce }) => {
        setRegistros(nfce.content);
        setTotalRecords(nfce.totalElements);
        setFirstRender(false);
      });
    }
  }

  function buscarFiltro() {
    let filtroRSQL = String('?query=(')
      .concat(`nro=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
      .concat(`serie=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
      .concat(`destNome=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
      .concat(`destCpf=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
      .concat(`destCnpj=contains="*${valorPesquisa.replaceAll('&', '%26')}*",`)
      .concat(`status=contains="*${valorTiposEnumerados.replaceAll('&', '%26')}*")`);

    if (filtroData) {
      filtroRSQL = filtroRSQL.concat(`;(${filtroData})`);
    } else {
      filtroRSQL = filtroRSQL.concat(`;(${currentMonth()})`);
    }

    if (filtroAvancado) {
      filtroRSQL = filtroRSQL.concat(`;(${filtroAvancado})`);
    }

    return filtroRSQL;
  }

  function onPesquisarFiltroAvancado(e) {
    setFiltroAvancado(e);
    setLimparFiltroPesquisaAvancada(false);
  }

  async function asyncSelectRegistro(idNFCe) {
    await asyncGetNfce(idNFCe, async nfce => {
      let dadosFormulario = converterNfceParaFormulario(nfce.data);

      setPodeInserir(
        usuarioPossuiPermissao(recursos.VENDAS_NOTAS_CONSUMIDOR, permissoes.INSERIR) &&
          dadosFormulario?.status === Status.NAO_ENVIADA
      );

      await resetForm({ values: dadosFormulario });
    });
  }

  function acessarNFCe(row) {
    atualizarUrl(props.history, `/nfces/cadastro/${row.id}`);
    asyncSelectRegistro(row.id);
    onHide();
  }

  function baixarArquivosXml() {
    setMostrarLoading(true);
    asyncGetDownloadXmls(
      buscarFiltro(),
      ({ data: data }) => {
        baixarArquivo(
          data,
          `ArquivosXmlNFCe_${format(interval.dataInicial, 'dd-MM-yyyy')}_${format(
            interval.dataFinal,
            'dd-MM-yyyy'
          )}.zip`
        );
        setMostrarLoading(false);
      },
      () => {
        setMostrarLoading(false);
      }
    );
  }

  return (
    <>
      <ModalLoadingTransmissao visible={mostrarLoading} message="Gerando arquivo com os XMLs..." />
      <Modal
        header="Listagem NFC-e"
        onHide={onHide}
        visible={visible}
        styleModal={{
          minWidth: '60%',
        }}
        isMobile={isMobile}
        container={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
      >
        <Button
          label="Download XMLs"
          className="step-listagem-novo p-button-primary"
          icon="fa fa-file-archive-o"
          title="Download dos arquivos XMLs das NFC-es filtradas"
          style={{ margin: '5px' }}
          onClick={() => baixarArquivosXml()}
        />
        <Grid justifyCenter verticalAlignCenter>
          <span style={{ padding: '12px' }}>
            <DateInterval
              onChange={handleChangeInterval}
              appendTo={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
              interval={interval}
            />
          </span>
          <InputSearch
            onPesquisar={onPesquisar}
            className="step-listagem-input-search"
            value={valorPesquisa}
            onChange={value => setValorPesquisa(value)}
            removerEComercial={false}
            id="nfce-input-search"
          />
          <span style={{ padding: '12px' }} className="step-listagem-filtro-avancado">
            <PesquisaAvancada
              optionsFiltros={optsFiltroAvancado}
              onPesquisarClick={onPesquisarFiltroAvancado}
              onChangeFiltroRsql={rsql => setFiltroAvancado(rsql)}
              onChangeDescricaoFiltro={e => setDescricaoFiltroAvancado(e)}
              limparFiltro={limparFiltroPesquisaAvancada}
              menuPortalTarget={isFullScreen ? document.getElementsByClassName('layout-fullscreen')[0] : null}
            />
          </span>
        </Grid>
        <DescricaoFiltroAvancado texto={descricaoFiltroAvancado} />
        <Grid style={{ margin: 0, padding: 0 }}>
          <TabelaNFCe
            registros={registros}
            sortField={sortField}
            sortOrder={sortOrder}
            totalRecords={totalRecords}
            rows={size}
            page={page}
            onPageChange={e => {
              setSize(e.rows);
              setRows(e.rows);
              setPage(e.page);
            }}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            isMobile={isMobile}
            isTablet={isTablet}
            isLessHd={isLessHd}
            acessarNFCe={row => {
              if (dirty) {
                confirm(
                  'Confirmação',
                  'Existem informações preenchidas na NFC-e, ao acessar o documento selecionado você vai perder essas informações. Deseja continuar? ',
                  () => acessarNFCe(row),
                  () => {},
                  'Sim',
                  'Não'
                );
              } else {
                acessarNFCe(row);
              }
            }}
          />
        </Grid>
      </Modal>
    </>
  );
}

export default ModalListagem;
