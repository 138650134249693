import { services } from 'Common';
import SingleSelect from '../SingleSelect';
import PropTypes from 'prop-types';

export const typeIntegracaoServico = {
	IPM_V1: 'IPM_V1',
	IPM_V2: 'IPM_V2',
	BETHA: 'BETHA',
	SIGCORP: 'SIGCORP',
};

export default function SingleSelectServicoCst(props) {
	const { label, type, isClearable, onChange } = props;

	function buscarUrlPesquisa(pesquisa, page) {
		let integracaoFilter = '';

		switch (type) {
			case typeIntegracaoServico.IPM_V1: {
				integracaoFilter = `(integracao=="${typeIntegracaoServico.IPM_V1}");`;
				break;
			}
			case typeIntegracaoServico.IPM_V2: {
				integracaoFilter = `(integracao=="${typeIntegracaoServico.IPM_V2}");`;
				break;
			}
			case typeIntegracaoServico.BETHA: {
				integracaoFilter = `(integracao=="${typeIntegracaoServico.BETHA}");`;
				break;
			}
			case typeIntegracaoServico.SIGCORP: {
				integracaoFilter = `(integracao=="${typeIntegracaoServico.SIGCORP}");`;
				break;
			}
		}

		let query = `?query=${integracaoFilter}(descricao=contains="*${pesquisa}*",codigo=contains="${pesquisa}*")&page=${page}&size=50`;

		return `${services.GESTOR}/v1/tributacoes/municipal/servico_cst${query}`;
	}

	function montarLabel(row) {
		return `${row.codigo} - ${row.descricao}`;
	}

	return (
		<SingleSelect
			{...props}
			buscarUrlPesquisa={buscarUrlPesquisa}
			montarLabel={montarLabel}
			label={label}
			onChange={onChange}
			isClearable={isClearable}
			esconderBotao
		/>
	);
}

SingleSelectServicoCst.propTypes = {
	label: PropTypes.string,
	type: PropTypes.string.isRequired,
	isClearable: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

SingleSelectServicoCst.defaultProps = {
	label: 'Situação tributária',
	isClearable: false,
};
