import { useEffect, useState } from 'react';
import ModalTributacaoMunicipal from 'views/cadastros/fiscal/TributacaoMunicipal/Modal';
import { services } from 'Common';
import SingleSelect from '../SingleSelect';

export default function SingleSelectTributacaoMunicipal(props) {
	const { label, isClearable, errors, touched, isMobile, service, setHideBackground } = props;
	const [visible, setVisible] = useState(false);
	const [resetPesquisa, setResetPesquisa] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		if (setHideBackground) {
			setHideBackground(visible);
		}
	}, [visible]);

	useEffect(() => {
		setIsMounted(true);

		return () => {
			setIsMounted(false);
		};
	}, []);

	function buscarUrlPesquisa(pesquisa, page) {
		let query = `?query=(nome=contains="*${pesquisa}*",codigo=contains="${pesquisa}*");situacao=="ATIVO"&page=${page}&size=50`;

		return `${services.GESTOR}/v1/tributacoes/municipal/resumo${query}`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function onHide(novoRegistro) {
		setarNovoRegistroNoSelect(novoRegistro);
		esconderModal();
	}

	function montarLabel(row) {
		return row.codigo + ' - ' + row.nome;
	}

	function setarNovoRegistroNoSelect(novoRegistro) {
		if (novoRegistro?.id && isMounted) {
			props.onChange(
				{
					label: montarLabel({
						codigo: novoRegistro.codigo,
						nome: novoRegistro.nome,
					}),
					registro: {
						id: novoRegistro.id,
						codigo: novoRegistro.codigo,
						nome: novoRegistro.nome,
						situacao: novoRegistro.situacao.value,
					},
					value: novoRegistro.id,
				},
				props.index
			);
			setResetPesquisa(true);
		}
	}

	return (
		<>
			<ModalTributacaoMunicipal
				service={service}
				visible={visible}
				onHide={onHide}
				setHideBackground={setHideBackground}
			/>
			<SingleSelect
				{...props}
				titleBotaoNovo={'Adicionar nova operação fiscal'}
				buscarUrlPesquisa={buscarUrlPesquisa}
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				label={label}
				onChange={props.onChange}
				isClearable={isClearable}
				useFormErrors={false}
				useFormTouched={false}
				errors={errors}
				touched={touched}
				styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
				menuPortalTarget={document.body}
				placeholder={isMobile ? 'Selecione uma opção' : 'Digite para pesquisar'}
				resetPesquisa={resetPesquisa}
				setResetPesquisa={setResetPesquisa}
			/>
		</>
	);
}

SingleSelectTributacaoMunicipal.defaultProps = {
	label: 'Tributação municipal',
};
