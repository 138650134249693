import { isValidCEP, isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import { useFormikContext, withFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
	buscarConfiguracaoUsuario,
	buscarDadosLoginLocalStorage,
	configuracoesUsuario,
	isEstadoAdjacente,
	manterApenasNumeros,
	mensagensDeValidacao,
	salvarConfiguracaoUsuario,
	validarUUID,
} from 'Common';
import {
	Form,
	FormActions,
	FormContent,
	If,
	ModalLoadingTransmissao,
	Prompt,
	Tutorial,
	tutorialStepsMdfe,
} from 'components';

import { atualizarUrl, metodosAtualizarUrl } from 'views/Util';
import { TIPO_PESSOA } from 'views/cadastros/Pessoas/Util/constantes';

import { ActionButtons } from './components/ActionButtons';
import { ErrosMessage } from './components/ErrosMessage';
import { EventosPosteriores } from './components/EventosPosteriores';
import { Header } from './components/Header';
import { HeaderForm } from './components/HeaderForm';
import { InformacoesPrincipais } from './components/InformacoesPrincipais';
import { TabsComplementares } from './components/TabsComplementares';
import { TabsPrestadorServico } from './components/TabsPrestadorServico';
import { TabsPrincipais } from './components/TabsPrincipais';

import { useContextMDFe } from '../Context';
import { readMDFe, readUsuarioFavorito } from '../Requests';
import { converterMDFeParaForm } from './Util/MDFeConverter';
import { CADASTRO_URL, INITIAL_VALUES, MDFE_SITUACAO, MDFE_TIPO_EMITENTE } from './Util/constantes';
import { converterValueParaSelect, isPercursoCompleta } from './Util/functions';

import './Styles/index.css';

function MDFeFormImpl() {
	const { values, dirty, errors, submitCount, resetForm } = useFormikContext();

	const {
		match,
		history,
		exibirLoadingTransmissao,
		setIsSituacaoFinalMDFe,
		setActiveTabPrincipais,
		setActiveTabServicos,
		setActiveTabComplementares,
		setErrosTransmissao,
		messageLoading,
	} = useContextMDFe();
	const dadosLogin = buscarDadosLoginLocalStorage();

	const [tutorialVisible, setTutorialVisible] = useState(false);

	const deveExibirTutorial = buscarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_MDFE);

	useEffect(async () => {
		const { id } = match.params;

		if (deveExibirTutorial !== false) {
			setTutorialVisible(true);
			salvarConfiguracaoUsuario(configuracoesUsuario.EXIBIR_TUTORIAL_MDFE, false, null, false);
		}

		if (validarUUID(id)) {
			fetchRegistro(id);
		} else {
			await novo();
		}
		setErrosTransmissao({});

		setTimeout(() => {
			document.getElementById('veiculo-tracao')?.getElementsByTagName('input')[0]?.focus();
		}, 500);
	}, []);

	useEffect(() => {
		setIsSituacaoFinalMDFe(
			[
				MDFE_SITUACAO.AGUARDANDO_AUTORIZACAO,
				MDFE_SITUACAO.CANCELADO,
				MDFE_SITUACAO.DENEGADO,
				MDFE_SITUACAO.ENCERRADO,
				MDFE_SITUACAO.TRANSMITIDO,
			].includes(values.situacao)
		);
	}, [values.situacao]);

	useEffect(() => {
		if (Object.keys(errors)?.length > 0) {
			const hasErrorTabTotalizadores =
				errors?.total?.quantidadeDocumento || errors?.total?.pesoBruto || errors?.total?.valorTotal;
			const hasErrorTabInformacoesComplementares =
				errors?.informacaoComplementar?.usuario || errors?.informacaoComplementar?.setor;

			if (hasErrorTabTotalizadores) {
				setActiveTabComplementares(0);
			} else if (hasErrorTabInformacoesComplementares && !hasErrorTabTotalizadores) {
				setActiveTabComplementares(1);
			}
		}
	}, [errors, submitCount]);

	async function fetchRegistro(id) {
		await readMDFe(id, ({ data: mdfeApi }) => {
			resetForm({ values: converterMDFeParaForm(mdfeApi) });

			atualizarUrl(history, CADASTRO_URL, mdfeApi.id, metodosAtualizarUrl.POP);
		});
	}

	async function novo() {
		atualizarUrl(history, CADASTRO_URL, null, metodosAtualizarUrl.POP);
		setActiveTabPrincipais(0);
		setActiveTabServicos(0);
		setActiveTabComplementares(0);

		let usuarioFavorito = {};
		const promise = readUsuarioFavorito(({ data: response }) => {
			usuarioFavorito = converterValueParaSelect(response.content[0]);
		});

		promise.then(() => {
			resetForm({
				values: {
					...INITIAL_VALUES,
					serie: dadosLogin?.filialConectada?.parametrosFiscalMDFe?.serie ?? null,
					informacaoComplementar: {
						...INITIAL_VALUES.informacaoComplementar,
						usuario: usuarioFavorito,
						setor: converterValueParaSelect(dadosLogin?.setores[0]) ?? null,
					},
				},
			});
		});
	}

	return (
		<>
			<Prompt dirty={dirty} />
			<Tutorial
				steps={tutorialStepsMdfe}
				showSkipButton
				continuous
				visible={tutorialVisible}
				onHide={() => setTutorialVisible(false)}
			/>
			<ModalLoadingTransmissao visible={exibirLoadingTransmissao} message={messageLoading} />
			<Form
				header={<HeaderForm title="Manifesto de documentos fiscal eletrônico (MDF-e)" />}
				className="card-default screen-max-width"
			>
				<FormActions className="screen-max-width">
					<ActionButtons novoRegistro={novo} fetchRegistro={fetchRegistro} />
				</FormActions>
				<FormContent>
					<ErrosMessage />
					<Header />
					<InformacoesPrincipais />
					<TabsPrincipais />
					<If test={values.tipoEmitente === MDFE_TIPO_EMITENTE.PRESTADOR_SERVICO}>
						<TabsPrestadorServico />
					</If>
					<TabsComplementares />
				</FormContent>
			</Form>
			<EventosPosteriores fetchRegistro={fetchRegistro} />
		</>
	);
}

const MDFeForm = withFormik({
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,

	mapPropsToValues() {
		return INITIAL_VALUES;
	},

	validate(values) {
		const errors = {};
		const errorsCiot = {};
		const errorsTotal = {};
		const errorsProdutoPredominante = {};

		if (values.total) {
			if (
				(!values.total.quantidadeDocumento || values.total.quantidadeDocumento <= 0) &&
				!values.informacaoComplementar.permiteCarregamentoPosterior
			) {
				errorsTotal.quantidadeDocumento = 'Mínimo 1 documento';
			}
			if (!values.total.pesoBruto || values.total.pesoBruto <= 0) {
				errorsTotal.pesoBruto = mensagensDeValidacao.OBRIGATORIO;
			}
			if (!values.total.valorTotal || values.total.valorTotal <= 0) {
				errorsTotal.valorTotal = mensagensDeValidacao.OBRIGATORIO;
			}
		}

		const isCarregamentoDescarregamentoAdjacente =
			isEstadoAdjacente(values.ufCarregamento?.registro?.sigla, values.ufDescarregamento?.registro?.sigla) ||
			!values.ufCarregamento ||
			!values.ufDescarregamento;

		if (!isCarregamentoDescarregamentoAdjacente) {
			if (
				!isPercursoCompleta(
					values.ufsPercurso,
					values.ufCarregamento?.registro?.sigla,
					values.ufDescarregamento?.registro?.sigla
				)
			) {
				errors.ufsPercurso = 'Percurso não finalizado';
			}
		}

		if (values.condutores?.length <= 0) {
			errors.condutores = 'Informe ao menos um condutor';
		}

		if (values.tipoEmitente === MDFE_TIPO_EMITENTE.PRESTADOR_SERVICO) {
			if (values.seguradoras?.length <= 0) {
				errors.seguradoras =
					'MDF-e com "Tipo emitente" igual a "Prestrador de serviço de transporte" deve informar ao menos uma seguradora';
			}

			if (values.ciot) {
				if (values.ciot?.numeroCiot && values.ciot?.numeroCiot?.length !== 12) {
					errorsCiot.numeroCiot = 'Tamanho mínimo de 12 dígitos';
				}
				if (values.ciot?.tipoPessoa === TIPO_PESSOA.JURIDICA && values.ciot?.cnpj) {
					if (!isValidCNPJ(manterApenasNumeros(values.ciot?.cnpj))) {
						errorsCiot.cnpj = mensagensDeValidacao.CNPJ_INVALIDO;
					}
				} else if (values.ciot?.tipoPessoa === TIPO_PESSOA.JURIDICA && values.ciot?.cpf) {
					if (!isValidCPF(manterApenasNumeros(values.ciot?.cpf))) {
						errorsCiot.cpf = mensagensDeValidacao.CPF_INVALIDO;
					}
				}
			}

			if (values.produtoPredominante) {
				if (!values.produtoPredominante?.tipoCarga) {
					errorsProdutoPredominante.tipoCarga = mensagensDeValidacao.OBRIGATORIO;
				}
				if (!values.produtoPredominante?.descricao) {
					errorsProdutoPredominante.descricao = mensagensDeValidacao.OBRIGATORIO;
				}
				if (!values.produtoPredominante?.carregamentoCep) {
					errorsProdutoPredominante.carregamentoCep = mensagensDeValidacao.OBRIGATORIO;
				} else if (!isValidCEP(values.produtoPredominante?.carregamentoCep)) {
					errorsProdutoPredominante.carregamentoCep = mensagensDeValidacao.CEP_INVALIDO;
				}
				if (!values.produtoPredominante?.descarregamentoCep) {
					errorsProdutoPredominante.descarregamentoCep = mensagensDeValidacao.OBRIGATORIO;
				} else if (!isValidCEP(values.produtoPredominante?.descarregamentoCep)) {
					errorsProdutoPredominante.descarregamentoCep = mensagensDeValidacao.CEP_INVALIDO;
				}
				if (values.produtoPredominante?.codigoEan) {
					if (!(values.produtoPredominante?.codigoEan > 12 && values.produtoPredominante?.codigoEan < 14)) {
						errorsProdutoPredominante.codigoEan = 'campo deve conter de 12 a 14 dígitos';
					}
				}
			}
		}

		if (Object.keys(errorsCiot).length > 0) {
			errors.ciot = errorsCiot;
		}
		if (Object.keys(errorsProdutoPredominante).length > 0) {
			errors.produtoPredominante = errorsProdutoPredominante;
		}
		if (Object.keys(errorsTotal).length > 0) {
			errors.total = errorsTotal;
		}

		return errors;
	},

	validationSchema: Yup.object().shape({
		tipoEmitente: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		tipoTransportador: Yup.string().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		emissao: Yup.date().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		veiculoTracao: Yup.object().shape({
			veiculo: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
			placa: Yup.string().nullable().required('Placa é um campo obrigatório'),
			renavam: Yup.string()
				.nullable()
				.required('Renavam é um campo obrigatório')
				.min(9, 'Tamanho mínimo de 9 dígitos')
				.max(11, 'Tamanho máximo de 11 dígitos'),
			estado: Yup.object().nullable().required('Estado é um campo obrigatório'),
			tara: Yup.string().nullable().required('Tara é um campo obrigatório').min(1, 'Valor mínimo é 1'),
			capacidade: Yup.string().nullable().required('Capacidade é um campo obrigatório'),
			rodado: Yup.string().nullable().required('Rodado é um campo obrigatório'),
			carroceria: Yup.string().nullable().required('Carroceria é um campo obrigatório'),
		}),
		ufCarregamento: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		ufDescarregamento: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		municipioCarregamento: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		informacaoComplementar: Yup.object().shape({
			usuario: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
			setor: Yup.object().nullable().required(mensagensDeValidacao.OBRIGATORIO),
		}),
	}),

	handleSubmit: () => {},
})(MDFeFormImpl);

export { MDFeForm };
