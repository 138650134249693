import { useEffect, useRef, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { TreeTable } from 'primereact/treetable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { GiPayMoney, GiPlainCircle, GiReceiveMoney } from 'react-icons/gi';
import { GoAlertFill, GoBookmark } from 'react-icons/go';

import { Button, Grid, If, NenhumRegistroEncontrado } from 'components';

import { colors } from 'Common';
import { converterParaTreeTable } from './Util/converter';
import { CATEGORIA_ALTERACAO_TIPO } from './Util';
import { TreeTabelaCategoriaOverlayCategoriaComInconsistencia } from './components/TreeTabelaCategoriaOverlayCategoriaComInconsistencia';

const styleButton = {
	borderRadius: '50%',
	padding: '5px',
	width: '30px',
	height: '30px',
	margin: '3px',
};

function TreeTabelaCategorias(props) {
	const { categorias, isMobile, tabName, pesquisar } = props;

	const [exibirCategoriasInativas, setExibirCategoriasInativas] = useState(false);
	const [categoriasTree, setCategoriasTree] = useState([]);
	const [registroSelecionado, setRegistroSelecionado] = useState(null);

	const prevPropsRef = useRef({ props });
	const prevStateRef = useRef(categoriasTree);
	const menuOpcoes = useRef(null);
	const panelCategoriaComInconsistencia = useRef(null);

	const exibirTabela = Boolean(categoriasTree.root && categoriasTree.root.length > 0);

	useEffect(() => {
		const prevProps = prevPropsRef.current;
		const prevState = prevStateRef.current;
		if (prevProps.categorias !== props.categorias || prevState.exibirCategoriasInativas !== exibirCategoriasInativas) {
			let categoriasFiltradas = [];
			if (exibirCategoriasInativas) {
				categoriasFiltradas = categorias;
			} else {
				categoriasFiltradas = filtrarCategoriasAtivas(categorias);
			}
			setCategoriasTree(converterParaTreeTable(categoriasFiltradas));
		}
	}, [props, exibirCategoriasInativas]);

	function filtrarCategoriasAtivas(categorias) {
		const result = [];

		for (const categoria of categorias) {
			if (categoria.ativa) {
				result.push({
					...categoria,
					subCategorias: filtrarCategoriasAtivas(categoria.subCategorias),
				});
			}
		}

		return result;
	}

	function renderAcoesField(row) {
		if (isMobile) {
			return acoesMobile(row);
		} else {
			return acoesTelaNormal(row);
		}
	}

	function acoesMobile(row) {
		const acoesDoBotaoMobile = buscarAcoesMobile(row);
		return (
			<span>
				<Button
					color="secondary"
					style={styleButton}
					icon="fa fa-ellipsis-v"
					size="icon"
					disabled={Boolean(acoesDoBotaoMobile.length === 0)}
					title={acoesDoBotaoMobile.length === 0 ? 'Nenhuma operação possível para esta categoria' : null}
					onClick={(event) => {
						menuOpcoes.current.toggle(event);
						setRegistroSelecionado(row);
					}}
				/>
			</span>
		);
	}

	function buscarAcoesMobile(row) {
		const { informacoesPermissoes } = props;

		const itens = [];

		if (row?.data) {
			itens.push({
				label: 'Aumentar nível',
				icon: 'fa fa-arrow-up',
				command: () => props.alterarNivel(CATEGORIA_ALTERACAO_TIPO.SUBTRAIR, row?.data),
				disabled: row?.primeiroRegistro,
			});
			itens.push({
				label: 'Diminuir nível',
				icon: 'fa fa-arrow-down',
				command: () => props.alterarNivel(CATEGORIA_ALTERACAO_TIPO.SOMAR, row?.data),
				disabled: row?.ultimoRegistro,
			});

			if (row?.data.ativa) {
				itens.push({
					label: 'Inativar',
					icon: 'fa fa-ban',
					command: () => props.inativarCategoria(row?.data),
				});
			} else {
				itens.push({
					label: 'Ativar',
					icon: 'fa fa-check',
					command: () => props.ativarCategoria(row?.data),
				});
			}

			if (informacoesPermissoes.podeVisualizar) {
				itens.push({
					label: 'Editar',
					icon: 'fa fa-pencil',
					command: () => {
						props.selecionarCategoria(row?.data);
						props.exibirModalCategoria();
					},
				});
			}

			if (informacoesPermissoes.podeExcluir) {
				itens.push({
					label: 'Excluir',
					icon: 'fa fa-trash',
					command: () => props.excluirCategoria(row?.data),
				});
			}
		}

		return itens;
	}

	function acoesTelaNormal(row) {
		const { informacoesPermissoes } = props;

		const { data } = row;

		return (
			<div style={{ display: 'flex' }}>
				<Button
					style={styleButton}
					className="p-button p-button-success"
					icon="fa fa-arrow-up"
					title={
						!informacoesPermissoes.podeEditar
							? 'Alterar nível: Você não possui permissão para executar essa ação'
							: 'Aumentar nível'
					}
					disabled={!informacoesPermissoes.podeEditar || row.primeiroRegistro}
					onClick={() => props.alterarNivel(CATEGORIA_ALTERACAO_TIPO.SUBTRAIR, data)}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-success"
					icon="fa fa-arrow-down"
					title={
						!informacoesPermissoes.podeEditar
							? 'Alterar nível: Você não possui permissão para executar essa ação'
							: 'Diminuir nível'
					}
					disabled={!informacoesPermissoes.podeEditar || row.ultimoRegistro}
					onClick={() => props.alterarNivel(CATEGORIA_ALTERACAO_TIPO.SOMAR, data)}
				/>

				{data.ativa ? (
					<Button
						style={styleButton}
						className="p-button p-button-secondary"
						icon="fa fa-ban"
						title={
							!informacoesPermissoes.podeEditar
								? 'Inativar: Você não possui permissão para executar essa ação'
								: 'Inativar'
						}
						disabled={!informacoesPermissoes.podeEditar}
						onClick={() => props.inativarCategoria(data)}
					/>
				) : (
					<Button
						style={styleButton}
						className="p-button p-button-secondary"
						icon="fa fa-check"
						title={
							!informacoesPermissoes.podeEditar ? 'Ativar: Você não possui permissão para executar essa ação' : 'Ativar'
						}
						disabled={!informacoesPermissoes.podeEditar}
						onClick={() => props.ativarCategoria(data)}
					/>
				)}
				<Button
					style={styleButton}
					className="p-button p-button-primary"
					icon="fa fa-pencil"
					title={
						!informacoesPermissoes.podeVisualizar
							? 'Editar: Você não possui permissão para executar essa ação'
							: 'Editar'
					}
					disabled={!informacoesPermissoes.podeVisualizar}
					onClick={() => {
						props.selecionarCategoria(data);
						props.exibirModalCategoria();
					}}
				/>
				<Button
					style={styleButton}
					className="p-button p-button-danger"
					icon="fa fa-trash"
					title={
						!informacoesPermissoes.podeExcluir
							? 'Excluir: Você não possui permissão para executar essa ação'
							: 'Excluir'
					}
					disabled={!informacoesPermissoes.podeExcluir}
					onClick={() => props.excluirCategoria(data)}
				/>
			</div>
		);
	}

	function renderNomeField(row) {
		const { data } = row;
		let marginLeftStyle = null;

		const keyLength = row.key.split('-').length;
		const isFirstLevel = !row.key?.includes('-');

		if (keyLength > 1) {
			marginLeftStyle = { marginLeft: `${keyLength * 16}px` };
		}

		const stylePrimeiroNivel = keyLength === 1 ? { fontWeight: 'bold', fontSize: '16px' } : null;
		const styleCategoriaTitulo = data.subCategorias?.length > 0 ? { fontWeight: 'bold' } : null;
		const style = data.ativa ? null : { opacity: '0.50' };
		const texto = data.ativa ? data.nome : `${data.nome} (Inativa)`;

		return (
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
				<span
					style={{
						...marginLeftStyle,
						...styleCategoriaTitulo,
						...style,
						...stylePrimeiroNivel,
						marginRight: '5px',
						display: 'flex',
						justifyItems: 'center',
					}}
					title={texto}
					onClick={(e) => {
						if (row?.data?.contemInconsistencias) {
							const overlayJaAberto = document.getElementById('overlayPanel-categoria-inconsistencia');
							if (!overlayJaAberto) {
								setRegistroSelecionado(row.data);
								panelCategoriaComInconsistencia.current?.toggle(e);
							}
						}
					}}
				>
					{`${row.nivel} - ${texto}`}
					{renderFavorita(row)}
					<If test={row?.data?.contemInconsistencias}>
						<GoAlertFill
							color={colors.vermelho}
							size={24}
							style={{ marginLeft: '5px' }}
							title="Categoria contém inconsistências"
						/>
					</If>
				</span>
				<If test={!isMobile && isFirstLevel}>
					<span style={{ display: 'flex', gap: '4px' }}>
						{data.receita ? (
							<GiReceiveMoney
								style={!data.ativa ? { opacity: '0.50' } : null}
								color={colors.verde}
								size={24}
								title="Categoria marcada para receitas"
							/>
						) : null}
						{data.despesa ? (
							<GiPayMoney
								style={!data.ativa ? { opacity: '0.50' } : null}
								color={colors.vermelho}
								size={24}
								title="Categoria marcada para despesas"
							/>
						) : null}
					</span>
				</If>
			</div>
		);
	}

	function renderFavorita(row) {
		return (
			<>
				{row.data.favoritaReceita ? (
					<GoBookmark size={22} color={colors.verde} title="Categoria favorita de receita" />
				) : null}
				{row.data.favoritaDespesa ? (
					<GoBookmark size={22} color={colors.vermelho} title="Categoria favorita de despesa" />
				) : null}
			</>
		);
	}

	function renderCorField(row) {
		const { data } = row;
		const temPai = row.key.split('-').length > 1;

		return (
			<If test={!temPai}>
				<GiPlainCircle style={!data.ativa ? { opacity: '0.50' } : null} color={data.aparencia} size={24} />
			</If>
		);
	}

	function renderExibirCategoriasInativas() {
		return (
			<span style={{ display: 'flex', justifyContent: 'flex-end', margin: '15px 0px' }}>
				<Checkbox
					inputId={`exibitCategoriasCheck-${tabName}`}
					checked={exibirCategoriasInativas}
					name={`exibitCategoriasCheck-${tabName}`}
					onChange={() => onClickExibirContasInativas()}
				/>
				<label htmlFor={`exibitCategoriasCheck-${tabName}`} className="p-checkbox-label p-checkbox-label-categorias">
					Exibir categorias inativas
				</label>
			</span>
		);
	}

	function onClickExibirContasInativas() {
		setExibirCategoriasInativas(!exibirCategoriasInativas);
	}

	return (
		<>
			<Menu model={buscarAcoesMobile(registroSelecionado)} popup style={{ minWidth: '230px' }} ref={menuOpcoes} />
			<If test={exibirTabela}>
				<TreeTable
					tableClassName="tree-table-categorias"
					value={categoriasTree.root}
					expandedKeys={categoriasTree.chaves}
					showGridlines
					onToggle={(e) => {
						setCategoriasTree((state) => ({ ...state, chaves: e.value }));
					}}
					responsive
					header={renderExibirCategoriasInativas()}
				>
					<Column header="Cor" field="aparencia" body={(e) => renderCorField(e)} style={{ width: '100px' }} expander />
					<Column
						header="Nome"
						field="nome"
						body={(e) => renderNomeField(e)}
						style={{ textAlign: 'justify', wordBreak: 'break-all' }}
					/>

					<Column header="Ações" body={(e) => renderAcoesField(e)} style={{ width: `${isMobile ? '60' : '200'}px` }} />
				</TreeTable>
			</If>
			<If test={!exibirTabela}>
				{renderExibirCategoriasInativas()}
				<NenhumRegistroEncontrado message="Nenhuma categoria encontrada" />
				<div style={{ color: '#999999', width: '100%' }}>
					<Grid justifyCenter style={{ padding: '15px' }}>
						{`Clique no botão "Exibir categorias inativas" para visualizar todas as categorias`}
					</Grid>
				</div>
			</If>

			<OverlayPanel
				id="overlayPanel-categoria-inconsistencia"
				ref={panelCategoriaComInconsistencia}
				style={{
					width: isMobile ? '90%' : '100%',
					maxWidth: '30.5rem',
					marginLeft: isMobile ? '1rem' : null,
					marginTop: '0px',
				}}
				onHide={() => {
					setRegistroSelecionado(null);
				}}
				showCloseIcon
			>
				<TreeTabelaCategoriaOverlayCategoriaComInconsistencia
					registroSelecionado={registroSelecionado}
					pesquisar={pesquisar}
				/>
			</OverlayPanel>
		</>
	);
}

export default TreeTabelaCategorias;
