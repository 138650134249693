import { useState, useEffect, useRef } from 'react';
import SingleSelect from '../SingleSelect';
import { usuarioPossuiPermissao, recursos, permissoes, usePrevious, formatarTelefone } from 'Common';
import ModalPessoas from '../../../views/cadastros/Pessoas/Modal';
import { tabsCadastro } from '../../../views/cadastros/Pessoas/Util/constantes';

export default function SingleSelectPessoaTelefone({ url, idPessoa, afterNewTelefone, onChange, mask, ...props }) {
	const [visible, setVisible] = useState(false);
	const [podeInserir, setPodeInserir] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.INSERIR));
	const [podeVisualizar, setPodeVisualizar] = useState(usuarioPossuiPermissao(recursos.PESSOAS, permissoes.VISUALIZAR));
	const [resetarPesquisa, setResetarPesquisa] = useState(false);
	const afterFirstRenderRef = useRef(false);
	const prevProps = usePrevious(props);

	const podeInserirEVisualizar = podeInserir && podeVisualizar;

	useEffect(() => {
		if (afterFirstRenderRef) {
			if (prevProps?.resetarPesquisa !== resetarPesquisa) {
				setResetarPesquisa(!resetarPesquisa);
			}
		} else afterFirstRenderRef = true;
	}, []);

	function buscarUrlPesquisa(pesquisa, page) {
		return `${url}?query=numero=contains="*${pesquisa}*"&page=${page}&size=50&sort=numero`;
	}

	function mostrarModal() {
		setVisible(!visible);
	}

	function esconderModal() {
		setVisible(false);
	}

	function onHide() {
		esconderModal();
		setResetarPesquisa(!resetarPesquisa);
		if (afterNewTelefone) {
			afterNewTelefone();
		}
	}

	function montarLabel(row) {
		return formatarTelefone(row.telefone);
	}

	return (
		<>
			<ModalPessoas onHide={onHide} visible={visible} idPessoa={idPessoa} tabSelecionada={tabsCadastro.TELEFONES} />
			<SingleSelect
				{...props}
				buscarUrlPesquisa={buscarUrlPesquisa}
				titleBotaoNovo="Adicionar novo telefone"
				montarLabel={montarLabel}
				onClickModal={mostrarModal}
				disabledButton={!podeInserirEVisualizar}
				onChange={onChange}
				resetarPesquisa={resetarPesquisa}
			/>
		</>
	);
}
