export const INITIAL_VALUES = {
	id: '',
	nome: '',
	favoritaReceita: false,
	favoritaDespesa: false,
	receita: false,
	despesa: false,
	ativa: true,
	aparencia: '#000000',
	categoriaSuperior: null,
};
